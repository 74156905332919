import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import shippingUtils from 'utils/shippingMethods';
import { useCart, useSetCart } from 'contexts';
import { copyText, lang } from 'language';
import storesApi from 'dataAccess/api/stores.ts';
import { string } from 'prop-types';
import { Close } from '@mui/icons-material';

const ShippingMethod = ({ sku, lineItemId }) => {
  const [availableMethods, setAvailableMethods] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState('');
  const [removeMattress, setRemoveMattress] = useState(false);
  const [storeKey, setStoreKey] = useState('');
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [showMsg, setShowMsg] = useState(false);
  const [loading, setLoading] = useState(true);
  const cart = useCart();
  const setCart = useSetCart();

  const populateStoreKey = (cartToCheck) => {
    const shippingMethod = shippingUtils.getSelectedShippingMethod(cartToCheck, lineItemId);
    if (shippingMethod?.custom?.fields?.storeKey) {
      setStoreKey(shippingMethod.custom.fields.storeKey);
    } else {
      setStoreKey(''); // Handle the case when storeKey is not available
    }
  };

  const displayAdditionalOptions = () => {
    return selectedShipping === 'Pick Up' || selectedShipping === 'In-Home Setup' ? 8 : 16;
  };

  const getStores = async () => {
    setLoading(true);
    const result = await storesApi.getStores();
    setStores(result);
    setLoading(false);
  };

  const updateStore = async (store) => {
    setLoading(true);
    try {
      const updatedCart = await shippingUtils.setSkuStore(sku, store.key, cart);

      if (updatedCart) {
        setCart(updatedCart);
        populateStoreKey(updatedCart);
      } else {
        throw new Error(copyText.Cart.CartTools.updateCart); // Handle the case of a null response
      }
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const populateAvailableShippingMethods = async () => {
    setLoading(true);
    try {
      const methods = await shippingUtils.getAvailableShippingMethods(cart?.id, sku);
      setAvailableMethods(methods);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const populateSelectedShippingMethod = () => {
    setLoading(true);
    try {
      const shippingMethod = shippingUtils.getSelectedShippingMethod(cart, lineItemId);
      setSelectedShipping(shippingMethod?.name[lang] ?? null);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const populateMattressRemoval = (cartToCheck) => {
    const shippingMethod = shippingUtils.getSelectedShippingMethod(cartToCheck, lineItemId);
    setRemoveMattress(shippingMethod.custom.fields.mattress_removal ? 1 : 0);
  };

  const updateMattressRemoval = async (value) => {
    setLoading(true);
    try {
      const updatedCart = await shippingUtils.updateMattressRemoval(cart, value, sku);
      setCart(updatedCart);
      populateMattressRemoval(updatedCart);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const updateShippingMethod = async (shippingMethod) => {
    setLoading(true);
    try {
      const newShippingMethod = shippingMethod;
      newShippingMethod['storeKey'] = cart?.store?.key;
      const currentShippingMethods = shippingUtils.getCurrentShippingMethods(cart);
      const updatedCart = await shippingUtils.updateShippingMethods(
        cart,
        sku,
        newShippingMethod,
        currentShippingMethods,
      );
      setCart(updatedCart);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setShowMsg(false);
  };

  const action = (
    <IconButton
      disabled={loading}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackbarClose}
    >
      <Close />
    </IconButton>
  );

  useEffect(() => {
    populateAvailableShippingMethods();
    populateSelectedShippingMethod();
    populateStoreKey(cart);
    populateMattressRemoval(cart);
    setLoading(false);
  }, [cart?.lineItems]);

  useEffect(() => {
    getStores();
  }, []);
  return (
    <Grid container display="flex" flexDirection="row" justifyContent="space-between" columns={16}>
      <Grid item xs={16} sm={displayAdditionalOptions()}>
        <FormControl data-testid="form-control-shippingMethod" fullWidth>
          <InputLabel id="select-shipping-method">
            {copyText.Cart.CartTools.shippingMethod}
          </InputLabel>
          <Select
            id="select-shipping-method"
            fullWidth
            label={copyText.Cart.CartTools.shippingMethod}
            value={selectedShipping}
            sx={{ height: '40px' }}
            IconComponent={() => null}
            disabled={loading}
            endAdornment={loading ? <CircularProgress size={25} color="primary" /> : null}
          >
            {availableMethods &&
              availableMethods.map((option) => {
                return (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    onClick={() => updateShippingMethod(option)}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      {selectedShipping === 'Pick Up' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="pickup-from-shipping">{copyText.Cart.CartTools.pickupFrom}</InputLabel>
            <Select
              id="pickup-from-shipping"
              fullWidth
              label={copyText.Cart.CartTools.pickupFrom}
              sx={{ color: 'primary', height: '40px' }}
              value={storeKey}
              IconComponent={() => null}
              disabled={loading}
            >
              {stores.map((store) => {
                return (
                  <MenuItem key={store.key} value={store.key} onClick={() => updateStore(store)}>
                    {store.name['en-US']}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {selectedShipping === 'In-Home Setup' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="select-remove-mattress">
              {copyText.Cart.CartTools.removeMattress}
            </InputLabel>
            <Select
              id="select-remove-mattress"
              fullWidth
              label={copyText.Cart.CartTools.removeMattress}
              sx={{ color: 'primary', height: '40px' }}
              value={removeMattress}
              onChange={(e) => updateMattressRemoval(e.target.value)}
              IconComponent={() => null}
              disabled={loading}
            >
              <MenuItem value={1}>{copyText.App.yes}</MenuItem>
              <MenuItem value={0}>{copyText.App.no}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Snackbar open={showMsg} onClose={handleSnackbarClose} message={error} action={action} />
    </Grid>
  );
};

ShippingMethod.propTypes = {
  sku: string,
  lineItemId: string,
};

ShippingMethod.defaultProps = {
  sku: '',
  lineItemId: '',
};

export default ShippingMethod;
