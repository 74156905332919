import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { useSelectedOrder, useOrderPayment } from 'contexts/orders';
import { copyText } from 'language';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import UseOrderSearchFunctions from '../OrderSearch/hooks/OrderSearchFunctions';
import ReturnModal from './ReturnModal';
import ReturnRow from './ReturnRow';

const Returns = () => {
  const selectedOrder = useSelectedOrder();
  const orderPayment = useOrderPayment();
  const { orderId } = useParams();
  const { useLoadOrderByNumber, useGetPaymentInfo } = UseOrderSearchFunctions();
  const [returnItems, setReturnItems] = useState([]);
  const [disable, setDisable] = useState(true);
  const [quantityErrorSku, setQuantityErrorSku] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [refundArray, setRefundArray] = useState([]);
  const orderColumns = {
    idColumn: copyText.Orders.Returns.productName,
    columns: [
      copyText.Orders.Returns.sku,
      copyText.Orders.Returns.price,
      copyText.Orders.Returns.available,
      // SUNRISE: (Adrian Rivera) we need to add these back in once we have refunds tied to a line item.
      // copyText.Orders.Returns.amountRefunded,
      // copyText.Orders.Returns.amountAvailable,
      // copyText.Orders.Returns.shippingStatus,
      // copyText.Orders.Returns.refundStatus,
      copyText.Orders.Returns.returnQuantity,
    ],
  };

  const formatOrderRefunds = (payment) => {
    const interactions = payment?.interfaceInteractions;
    const refunds = interactions?.map((interaction) => {
      if (interaction?.fields?.psp_id === 'RETURN') {
        return interaction?.fields?.refunded_line_items?.map((lineItem) => {
          return JSON.parse(lineItem);
        });
      }
      return null;
    });
    const flatArr = refunds?.flat();
    setRefundArray([...refundArray, ...flatArr]);
  };

  const loadOrderData = () => {
    if (!selectedOrder?.orderNumber) {
      useLoadOrderByNumber(orderId);
    }
  };

  const openModal = (event) => {
    event.preventDefault();
    setDisplayModal(true);
  };

  useEffect(() => {
    loadOrderData();
  }, []);

  useEffect(() => {
    useGetPaymentInfo(selectedOrder);
  }, [selectedOrder.orderNumber]);

  useEffect(() => {
    setRefundArray([]);
    orderPayment.forEach((payment) => formatOrderRefunds(payment));
  }, [orderPayment.length]);

  return (
    <form onSubmit={(event) => openModal(event)}>
      <Container disableGutters sx={{ mb: 5, p: 2 }}>
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ p: 1.5, mt: 5 }}>
          <Grid item xs={12} sm={6}>
            <Typography component="h1" variant="page_title">
              {copyText.Orders.Returns.return}
            </Typography>
            <Typography component="h2" variant="sub_header" color="secondary">
              {copyText.App.orderNumber}: {selectedOrder?.orderNumber}
            </Typography>
            <Box display="flex">
              <Typography component="h2" variant="sub_header" color="secondary">
                {copyText.App.email}: {selectedOrder?.customerEmail}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <TableContainer
              component={Paper}
              sx={{ height: { xs: '72vh', sm: '50vh', md: '55vh' }, pl: 1, pr: 1 }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ borderBottom: 3 }}>
                    <TableCell textAlign="center" />
                    <TableCell
                      key={orderColumns.idColumn}
                      sx={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      {orderColumns.idColumn}
                    </TableCell>
                    {orderColumns.columns.map((col) => (
                      <TableCell key={col} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedOrder?.lineItems?.length < 1 && (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ fontWeight: 'bold' }}>
                        {copyText.Layouts.TableLayout.noDataFound}
                      </TableCell>
                    </TableRow>
                  )}
                  {selectedOrder?.lineItems?.map((row) => (
                    <ReturnRow
                      key={row.id}
                      row={row}
                      orderRefunds={refundArray}
                      selectedOrder={selectedOrder}
                      quantityErrorSku={quantityErrorSku}
                      setQuantityErrorSku={setQuantityErrorSku}
                      returnItems={returnItems}
                      setReturnItems={setReturnItems}
                      setDisable={setDisable}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container display="flex" justifyContent="end">
          <Grid sm={12} item display="flex" justifyContent="center">
            {quantityErrorSku && (
              <Typography textAlign="center" color="error" variant="subtitle2" mb={1}>
                {copyText.Orders.Returns.quantityError}
              </Typography>
            )}
          </Grid>
          <Grid item sm={4} xs={12} display="flex" justifyContent="space-around">
            <Button
              component={RouterLink}
              to={`/order/${selectedOrder.orderNumber}`}
              variant="outlined"
              color="primary"
            >
              {copyText.Orders.Returns.cancel}
            </Button>
            <ReturnModal
              disableStart={disable}
              returnItems={returnItems}
              orderInfo={selectedOrder}
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
            />
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default Returns;
