import { useState } from 'react';
import { Menu, Box, Tooltip, IconButton, MenuItem, ListItemIcon } from '@mui/material';
import {
  ErrorOutline,
  ArticleOutlined,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { copyText } from 'language';
import LogoutButton from './Components/LogoutButton';
import DeviceSelect from '../DeviceSelect/DeviceSelect';

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    history.push(path);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'end' }}>
      <Tooltip title={copyText.UserMenu.toolTip}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={isOpen ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
        >
          <AccountCircleIcon sx={{ color: 'white' }} fontSize="large" />
        </IconButton>
      </Tooltip>
      {/* <Button
        onClick={handleClick}
        sx={{paddingLeft:0}}
      >
        <img
          src={isOpen ? chevronUp : chevronDown}
          alt="chevron icon"
        />
      </Button> */}
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LogoutButton />
        <Box>
          <MenuItem onClick={() => handleNavigate('/error-logs')}>
            <ListItemIcon>
              <ErrorOutline />
            </ListItemIcon>
            {copyText.ErrorLogs.viewLogs}
          </MenuItem>
        </Box>
        <Box>
          <MenuItem onClick={() => handleNavigate('/troubleshooting')}>
            <ListItemIcon>
              <ArticleOutlined />
            </ListItemIcon>
            {copyText.Troubleshooting.viewGuide}
          </MenuItem>
        </Box>
        <DeviceSelect />
      </Menu>
    </Box>
  );
};

export default UserMenu;
