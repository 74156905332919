import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useOktaAuth } from '@okta/okta-react';
import locationUtil from 'utils/storeLocation';
import haversine from 'utils/haversineCalc';
import { copyText, lang } from 'language';
import clover from 'utils/clover';
import SelectLocationModal from './SelectLocationModal';

const Location = () => {
  const { oktaAuth } = useOktaAuth();
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [selectedStore, setSelectedStore] = useState('' || null);
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    if (selectedStore !== '' || !selectedStore) {
      setOpenModal(false);
    } else {
      // TODO: show error snackbar that tells the user they need to select a store location
    }
  };

  const updateSelectedStore = (store) => {
    locationUtil.setLocationStorage(store);
    clover.updateCloverAccessToken(store.key);
    setSelectedStore(store);
  };

  const getClosestStore = async () => {
    if (storeList) {
      try {
        const usersLocation = await locationUtil.getLocation();
        let closest = null;
        let bestLocation = null;
        // Find closest store to users location
        storeList.forEach((store) => {
          const geoCoordinates = store.supplyChannels[0]?.obj.geoLocation?.coordinates;
          const geoCoordObj = {
            latitude: geoCoordinates[1],
            longitude: geoCoordinates[0],
          };

          // Calculate distance between geocoordinates
          const distance = haversine(geoCoordObj, usersLocation.coords);
          if (closest == null || distance < closest) {
            bestLocation = store;
            closest = distance;
            setLoading(false);
          }
        });
        return bestLocation;
      } catch (err) {
        // Return null if getting location returns an error (e.g. user denied sharing location)
        return null;
      }
    }
    // Should not get hit but return null if store locations list is empty
    return null;
  };

  const getStore = async () => {
    // Get store data from session storage
    const existingStore = await locationUtil.getLocationStorage();
    if (existingStore) {
      setLocationLoading(false);
      return existingStore;
    }

    // Find closest store location
    const closestStore = await getClosestStore();
    if (closestStore) {
      setLocationLoading(false);
      return closestStore;
    }

    // Default to null
    return null;
  };

  const selectStore = async () => {
    const foundStore = await getStore();

    if (foundStore) {
      updateSelectedStore(foundStore);
    } else {
      setOpenModal(true);
    }

    setLoading(false);
    setLocationLoading(false);
  };

  const populateStoreList = async () => {
    const list = await locationUtil.getStoreLocationsList();
    setStoreList(list);
  };

  useEffect(() => {
    if (storeList?.length > 0) {
      selectStore();
    } else {
      populateStoreList();
    }
  }, [oktaAuth.authStateManager._authState, storeList]);

  // Show loading indicator until done loading
  if (loading || locationLoading) {
    return <Box>{copyText.Location.loading}</Box>;
  }

  return (
    <>
      <SelectLocationModal
        storeList={storeList}
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
        updateSelectedStore={updateSelectedStore}
        openModal={openModal}
        closeModal={closeModal}
      />
      <Button onClick={() => setOpenModal(true)} endIcon={<ArrowDropDownIcon />}>
        <Typography color="white">{selectedStore?.name[lang] ?? ''}</Typography>
      </Button>
    </>
  );
};

export default Location;
