import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  DialogActions,
  InputLabel,
} from '@mui/material';
import { func, string, shape, bool, arrayOf } from 'prop-types';
import { copyText } from 'language';
import storeLocation from 'utils/storeLocation';

const OrderFilterModal = ({
  modalOpenStatus,
  selectedStore,
  setSelectedStore,
  closeFilterModal,
  searchFunction,
  locationList,
}) => {
  const searchFilter = (event) => {
    searchFunction(event.target.value);
    closeFilterModal();
  };

  return (
    <Dialog open={modalOpenStatus}>
      <DialogTitle id="filter-dialog-title">{copyText.Layouts.SearchLayout.filters}</DialogTitle>
      <DialogContent>
        <InputLabel id="order-store-select">{copyText.Orders.Orders.storeSelect}</InputLabel>
        <Select
          value={selectedStore?.key || 'DEFAULT'}
          id="order-store-select"
          variant="standard"
          disableUnderline
        >
          <MenuItem disabled value="DEFAULT">
            <em>{copyText.Orders.Orders.none}</em>
          </MenuItem>
          {locationList?.map((location) => (
            <MenuItem
              onClick={() => setSelectedStore(location)}
              key={location.key}
              value={location.key}
            >
              {storeLocation.getStoreNameByKey(location.key, locationList)}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={closeFilterModal}>
          {copyText.App.cancel}
        </Button>
        <Button variant="contained" color="primary" onClick={searchFilter}>
          {copyText.Orders.Orders.search}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderFilterModal;

OrderFilterModal.propTypes = {
  modalOpenStatus: bool,
  selectedStore: shape({ key: string }),
  setSelectedStore: func.isRequired,
  closeFilterModal: func.isRequired,
  searchFunction: func.isRequired,
  locationList: arrayOf(shape({})).isRequired,
};

OrderFilterModal.defaultProps = {
  modalOpenStatus: false,
  selectedStore: { key: '' },
};
