import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Divider,
  List,
  Typography,
  Container,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import cartService from 'dataAccess/api/cart.ts';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import { useOldCart, useSetCart, useSetOldCart } from 'contexts';
import TextLoader from 'components/TextLoader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { moveToTopOfHistory } from 'utils/cartHistory';
import { copyText } from 'language';
import CartHistoryItem from './components/CartHistoryItem';
import CartHistoryDetails from './components/CartHistoryDetails.jsx/CartHistoryDetails';

const defaultFormData = {
  customerEmail: null,
  agentEmail: null,
  draftsFilter: false,
  activeCarts: null,
};

const CartHistory = () => {
  const oldCart = useOldCart();
  const setOldCart = useSetOldCart();
  const setCart = useSetCart();
  const history = useHistory();

  const [foundCarts, setFoundCarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState(defaultFormData);

  const buildQuery = (_formData) => {
    const { customerEmail, agentEmail, draftsFilter, activeCarts } = _formData;

    // Customized searches cannot include active cart IDs
    if (customerEmail || agentEmail) {
      let query = 'cartState = "Active"';
      if (customerEmail) query += ` and customerEmail = "${customerEmail}"`;
      if (agentEmail) query += ` and createdBy(externalUserId = "${agentEmail}")`;
      if (draftsFilter) query += ` and custom(fields(is_draft_order = ${draftsFilter}))`;

      return query;
    }

    // --- Special case for active cart IDs ---
    let query = '';
    if (activeCarts) query = `id in (${activeCarts})`;
    if (draftsFilter) query += ` and custom(fields(is_draft_order = ${draftsFilter}))`;
    return query;
  };

  const getSessionCarts = async (data = formData) => {
    setLoading(true);
    const result = JSON.parse(sessionStorage.getItem('cartHistory'));
    if (result) {
      const activeCarts = result.filter((item) => item.status === 'active');
      const cartIdsFilter = activeCarts.map((item) => `"${item.cart}"`).join(',');

      const _formData = data;
      _formData.activeCarts = cartIdsFilter;
      const query = buildQuery(_formData);

      const payload = {
        filter: query,
      };

      const cartsById = await cartService.searchCart(payload);
      if (cartsById.results) {
        setFoundCarts(cartsById.results);
      }
    }
    setLoading(false);
  };

  const submitSearchQuery = async () => {
    const query = buildQuery(formData);
    try {
      setLoading(true);
      const payload = {
        filter: query,
        sortBy: 'createdAt',
      };
      const response = await cartService.searchCart(payload);
      if (response.results) {
        setFoundCarts(response.results);
      }
    } catch (error) {
      setStatus(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetCart = async () => {
    try {
      setLoadingCart(true);
      await setCart(oldCart);
      moveToTopOfHistory(oldCart.id);
      history.push('/cart');
    } catch (error) {
      setLoadingCart(false);
    } finally {
      setLoadingCart(false);
      setOldCart({});
    }
  };

  const updateForm = (e) => {
    // TODO: Add form validation
    let value = e.target.value;
    const key = e.target.getAttribute('name');

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleReset = () => {
    setFormData(defaultFormData);
    getSessionCarts(defaultFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSearchQuery();
  };

  useEffect(() => {
    getSessionCarts();
  }, []);

  // SUNSET: Refactor this to use page template after it's finished
  return (
    <Container disableGutters sx={{ width: '100%', mt: 10 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 1.5 }}>
        <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
          <Grid item sm={6}>
            <TextField
              placeholder={copyText.Cart.CartTools.CartHistory.searchByEmail}
              InputProps={{
                endAdornment: (
                  <Button
                    disabled={!formData.customerEmail}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {loading ? <CircularProgress color="secondary" size={25} /> : <SearchIcon />}
                  </Button>
                ),
              }}
              onChange={updateForm}
              variant="outlined"
              name="customerEmail"
              fullWidth
            />
          </Grid>
        </form>
      </Grid>
      {status && (
        <Grid
          item
          xs={16}
          sx={{ backgroundColor: 'lightAquaBG', height: '2.5rem', p: 5 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography>
              {copyText.Cart.CartTools.CartHistory.searchFailed}: {status}
            </Typography>
            <Typography>{copyText.Feedback.tryAgain}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setStatus()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Divider />
      <Grid container columns={16}>
        <Grid item xs={16} sm={6} display="flex" flexDirection="column">
          <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
            <Grid
              container
              flexDirection="column"
              spacing="2"
              sx={{ justifyContent: 'space-between', p: 2 }}
            >
              <Grid item>
                <Typography component="h2" sx={{ m: 2, fontSize: '1.75em' }}>
                  {copyText.Cart.CartTools.CartHistory.history}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="h3" sx={{ m: 2, fontSize: '1.5em' }}>
                  {copyText.Cart.CartTools.CartHistory.filters}
                </Typography>
              </Grid>
              <Grid item display="flex" s={12}>
                <FormControlLabel
                  label={copyText.Cart.CartTools.CartHistory.draftOrderFilter}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      value={formData.draftsFilter}
                      checked={formData.draftsFilter}
                      onChange={updateForm}
                      name="draftsFilter"
                    />
                  }
                />
              </Grid>
              <Grid item display="flex" s={12}>
                <TextField
                  placeholder={copyText.Cart.CartTools.CartHistory.agentEmail}
                  value={formData.agentEmail}
                  onChange={updateForm}
                  name="agentEmail"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} s={12}>
                <Button type="reset" onClick={handleReset}>
                  {copyText.Cart.CartTools.CartHistory.resetForm}
                </Button>
                <Button type="submit" variant="outlined">
                  {copyText.Cart.CartTools.CartHistory.updateFilters}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Divider />
          {foundCarts.length === 0 && (
            <Box display="flex" height="50vh" justifyContent="center" alignItems="center">
              {copyText.Cart.CartTools.CartHistory.noCartsFound}
            </Box>
          )}
          <List
            sx={{
              position: 'relative',
              overflow: 'scroll',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              maxHeight: '65vh',
              mb: 20,
              p: 1,
            }}
          >
            {foundCarts.length > 0 &&
              foundCarts.map((cart) => {
                return <CartHistoryItem key={cart.id} cart={cart} />;
              })}
          </List>
        </Grid>
        <Grid item xs={0} sm={0.25} alignItems="center">
          <Divider orientation="vertical" />
        </Grid>
        {oldCart.id ? (
          <Grid item xs={16} sm={9.75}>
            <Grid container alignItems="center">
              <Grid sx={{ p: 1, pt: 2 }} item sm={16} display="flex" justifyContent="space-between">
                <Typography sx={{ ml: 1 }} variant="h5" component="h1">
                  {copyText.Cart.CartTools.CartHistory.cartDetails}
                </Typography>
                <Button
                  disabled={!oldCart || loadingCart}
                  variant="contained"
                  onClick={handleSetCart}
                >
                  <TextLoader loading={loadingCart} text="Load this cart" size={20} />
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Box
              sx={{
                position: 'relative',
                overflow: 'scroll',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                maxHeight: '65vh',
                mb: 20,
                p: 1,
              }}
            >
              <CartHistoryDetails />
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            xs={16}
            sm={9.75}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="75vh"
          >
            <Typography component="p">
              {copyText.Cart.CartTools.CartHistory.selectCartToSeeDetails}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CartHistory;
