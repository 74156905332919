import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Snackbar, Alert } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useOktaClaims } from 'contexts';
import { copyText } from 'language';
import ReprintReceipts from 'views/Orders/components/ReprintReceipts';
import ExchangeModal from 'views/Orders/components/ExchangeModal';
import { useReturnMessage, useSelectedOrder, useSetReturnMessage } from 'contexts/orders';
import ExchangeOrderBacklink from 'views/Orders/components/ExchangeOrderBacklink';
import RefundModalTEMP from './RefundModalTEMP';
import EmailModal from '../../components/EmailModal';
// SUNSET: Uncomment after refunds are fully completed
// import ReturnModal from '../../components/ReturnModal';

const OrderActions = () => {
  const oktaClaims = useOktaClaims();
  const returnMessage = useReturnMessage();
  const setReturnMessage = useSetReturnMessage();
  const [showExchangeBacklink, setShowExchangeBacklink] = useState(false);
  const selectedOrder = useSelectedOrder();
  const handleClose = () => {
    setReturnMessage(false);
  };

  useEffect(() => {
    if (selectedOrder.custom?.fields?.original_order_id) {
      setShowExchangeBacklink(true);
    } else {
      setShowExchangeBacklink(false);
    }
  }, [selectedOrder]);

  const { orderId } = useParams();
  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Grid item sx={{ mr: 2 }}>
          <EmailModal />
        </Grid>
        <Grid item display="flex" justifyContent="center">
          <ReprintReceipts />
        </Grid>
        <Grid item sm={0.25} display="flex" justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item sm={1.5} display="flex" justifyContent="center">
          <ExchangeModal />
        </Grid>
        <Grid item sm={1.5} display="flex" justifyContent="center">
          <Button variant="outlined" component={RouterLink} to={`/order/${orderId}/return`}>
            {copyText.Orders.Actions.return}
          </Button>
        </Grid>
        <Grid item sm={1.5} display="flex" justifyContent="center">
          {oktaClaims?.mpos_manager && (
            // SUNSET: Uncomment after refunds are fully completed
            // <Button variant="outlined" component={RouterLink} to={`/order/${orderId}/refund`}>
            //   {copyText.Orders.RefundModal.refund}
            // </Button>
            <RefundModalTEMP />
          )}
        </Grid>
        {showExchangeBacklink && (
          <Grid item sm={3} display="flex" justifyContent="center">
            <ExchangeOrderBacklink />
          </Grid>
        )}
      </Grid>
      <Snackbar open={returnMessage} onClose={handleClose}>
        <Alert severity="success" onClose={handleClose}>
          {copyText.Orders.Returns.successful}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderActions;
