import CartService from 'dataAccess/api/cart.ts';
import { v4 as uuidv4 } from 'uuid';

const createCart = async () => {
  const anonymousId = uuidv4();
  const result = await CartService.createAnonymousCart(anonymousId);
  return result;
};

export default createCart;
