import stores from 'dataAccess/api/stores.ts';
import { copyText, lang } from 'language';

// --- Utilities ---
const formatLocation = (position) => {
  if (position.coords) {
    const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude } = position.coords;
    return {
      coords: {
        accuracy,
        altitude,
        altitudeAccuracy,
        heading,
        latitude,
        longitude,
      },
    };
  }
  return null;
};

// --- Setters ---
const setStoreLocation = (position) => {
  if (position) {
    const storeLocation = formatLocation(position);
    sessionStorage.setItem('store-location', JSON.stringify(storeLocation));
  }
};

const setStoreLocationsList = (storesList) => {
  if (storesList) {
    const formattedStoresList = JSON.stringify(storesList);
    sessionStorage.setItem('store-locations-list', formattedStoresList);
  }
};

const setLocationStorage = (store) => {
  sessionStorage.setItem('store-data', JSON.stringify(store));
};

// --- Populators ---
const populateLocationsList = async () => {
  const result = await stores.getStores();
  setStoreLocationsList(result);
};

// --- Getters ---
const getLocationStorage = () => {
  const storeLocationData = JSON.parse(sessionStorage.getItem('store-data'));
  if (storeLocationData) {
    return storeLocationData;
  }
  return null;
};

const getStoreLocationsList = async () => {
  let storesList = JSON.parse(sessionStorage.getItem('store-locations-list'));
  if (!storesList) {
    await populateLocationsList();
    storesList = JSON.parse(sessionStorage.getItem('store-locations-list'));
  }
  return storesList;
};

// TODO: unit tests for clearLocationStorage, getLocationStorage, and setLocationStorage
const getLocation = () => {
  return new Promise((resolve, reject) => {
    // Check session storage for user location
    const storeLocation = sessionStorage.getItem('store-location');
    if (storeLocation?.coords) {
      resolve(storeLocation);
    }

    // Ask user to share location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setStoreLocation(position);
        resolve(position);
      },
      (err) => {
        reject(err);
      },
    );
  });
};

const getStoreAddress = () => {
  const locationStorage = getLocationStorage();
  const address = locationStorage.supplyChannels[0]?.obj?.address || '';
  return address;
};

const getStoreKey = () => {
  const storeLocationData = JSON.parse(sessionStorage.getItem('store-data'));
  if (storeLocationData) {
    return storeLocationData.key;
  }
  return null;
};

const getStoreByKey = async (storeKey) => {
  const result = await stores.getStores();
  const foundStore = result.filter((store) => store.key === storeKey);
  return foundStore[0] || null;
};

const getStoreName = () => {
  const locationStorage = getLocationStorage();
  const address = locationStorage.name[lang] || 'Purple Innovation';
  return address;
};

// Needs static data; Promise objects break things
const getStoreNameByKey = (storeKey, locationList) => {
  if (storeKey && locationList) {
    const foundStore = locationList?.filter((store) => store.key === storeKey);
    return foundStore[0]?.name[lang] || copyText.Location.notAvailable;
  }
  return copyText.Location.notAvailable;
};

const clearLocationStorage = () => {
  sessionStorage.removeItem('store-data');
};

export default {
  setStoreLocationsList,
  setLocationStorage,
  populateLocationsList,
  getLocationStorage,
  getStoreLocationsList,
  getLocation,
  getStoreAddress,
  getStoreKey,
  getStoreByKey,
  getStoreName,
  getStoreNameByKey,
  clearLocationStorage,
};
