import { Box, Typography, Divider } from '@mui/material';
import React from 'react';
import pluralProducts from 'utils/PluralProducts';
import { useCart } from 'contexts';

const CartTitle = () => {
  const cart = useCart();
  return (
    <Box>
      <Typography variant="h1" sx={{ mb: 2, mt: 4 }}>
        Cart
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: -4,
        }}
      >
        {`${cart?.totalLineItemQuantity || 0} item${
          pluralProducts(cart?.totalLineItemQuantity || 0) ? 's' : ''
        }`}
      </Typography>
      <Divider />
    </Box>
  );
};

export default CartTitle;
