import { func, bool, shape, string, arrayOf } from 'prop-types';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { List, ListItem, Typography } from '@mui/material';
import { copyText } from 'language';
import { useCart, useSetCart } from 'contexts';
import CartService from 'dataAccess/api/cart.lineItem.ts';
import shippingMethodUtils from 'utils/shippingMethods';
import TextLoader from 'components/TextLoader';
import EditCartCard from './components/EditCartCard';

const EditCartModal = ({ product, handleClose, showModal, variants }) => {
  const [loading, setLoading] = useState(false);
  const cart = useCart();
  const setCart = useSetCart();
  const [selectedVariant, setSelectedVariant] = useState(product.variant);
  const handleSelection = (variant) => {
    setSelectedVariant(variant);
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      const itemToRemove = cart?.lineItems.find(
        (lineItem) => lineItem.variant.sku === product.variant.sku,
      );
      const quantityToReplace = itemToRemove.quantity;
      await CartService.removeItemFromCart(cart?.id, itemToRemove.id);
      const params = {
        cartId: cart?.id,
        sku: selectedVariant.sku,
        quantity: quantityToReplace,
      };
      const result = await CartService.addItemToCart(params);
      const shippingResult = await shippingMethodUtils.handleNewLineItem(
        result,
        selectedVariant.sku,
      );
      if (shippingResult === null) {
        setCart(result);
      } else {
        setCart(shippingResult);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert('changes not saved to cart');
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: 'lightGrayBG' }}>
        <Typography variant="h5" component="h5">
          {copyText.App.edit}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogTitle id="alert-dialog-title">{product.name['en-US']}</DialogTitle>
        <List>
          {variants.length > 0 &&
            variants.map((variant) => {
              return (
                <ListItem key={variant.sku}>
                  <EditCartCard
                    productName={product.name['en-US']}
                    variant={variant}
                    handleSelection={handleSelection}
                    selectedVariant={selectedVariant}
                  />
                </ListItem>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'lightGrayBG', p: 2 }}>
        <Button sx={{ m: 1 }} variant="outlined" onClick={handleClose}>
          <Typography sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }} variant="h3" component="span">
            {copyText.App.cancel}
          </Typography>
        </Button>
        <Button sx={{ m: 1 }} disabled={loading} variant="contained" onClick={saveChanges}>
          <Typography
            sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}
            color="white"
            variant="h3"
            component="span"
          >
            <TextLoader text={copyText.Cart.CartTools.updateCart} loading={loading} size={16} />
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditCartModal.propTypes = {
  handleClose: func.isRequired,
  showModal: bool.isRequired,
  product: shape({
    variant: shape({
      sku: string.isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
  variants: arrayOf(shape({})).isRequired,
};

export default EditCartModal;
