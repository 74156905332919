import { React, useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { copyText } from 'language';
import clover from 'dataAccess/api/clover.ts';
import cloverUtils from 'utils/clover';
import { useCart, useSetCart } from 'contexts';
import { createCart } from 'utils/cart';
import { addCartToHistory, updateProcessedCart } from 'utils/cartHistory';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import orders from 'dataAccess/api/orders.ts';
import ClearCart from 'components/ClearCart/ClearCart';
import ReceiptModal from 'components/ReceiptModal/ReceiptModal';
import exchanges from 'dataAccess/api/exchanges.ts';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import sendExchangeEmail from 'utils/exchangeEmailUtils';
import { bool } from 'prop-types';
import PaymentResponse from '../PaymentResponse/PaymentResponse';
import OrderFailureDialog from './Components/OrderFailureDialog';

const CheckoutButtons = ({ checkRefund }) => {
  const [paymentResponse, setPaymentResponse] = useState({});
  const [orderResponse, setOrderResponse] = useState();
  const [open, setOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [createOrderFailure, setCreateOrderFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const history = useHistory();
  const cart = useCart();
  const setCart = useSetCart();
  const borderRadius = 6;
  const height = 56;

  const clearCart = async () => {
    const newCart = await createCart();
    setCart(newCart);
    addCartToHistory(newCart);
  };

  const createOrder = async (paymentResult) => {
    const order = await orders.createOrderFromCart(cart?.id, paymentResult);
    setOrderResponse(order.data);
    setOpen(false);
    setOrderOpen(true);
    return { order, orderCreated: true };
  };

  const createExchangeOrder = async (params) => {
    const orderResult = await exchanges.createExchangeOrder(params);
    setOrderResponse(orderResult.data);
    setOpen(false);
    setOrderOpen(true);
    return { order: orderResult, orderCreated: true };
  };

  const updateErrorMessage = (error) => {
    if (typeof error === 'string') {
      setErrorMessage(error);
    } else {
      // TODO: get the error message from the error object.
      setErrorMessage(`${copyText.Cart.CheckoutButtons.error} ${JSON.stringify(error)}`);
    }
  };

  const loadOrder = (orderNumber) => {
    setOrderOpen(false);
    history.push({ pathname: `/orders/order/${orderNumber}` });
  };

  const processOrder = async (payment) => {
    try {
      await clover.displayThankYou();
      // create order, if success then clear cart
      if (cart?.is_exchange_order) {
        const params = {
          cartId: cart?.id,
          paymentResponse: payment,
          transactionType: 'charge',
        };
        return await createExchangeOrder(params);
      }
      return await createOrder(payment);
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
      throw new Error('Order creation failed.');
    } finally {
      setTimeout(() => {
        clover.displayWelcome();
      }, 3000);
    }
  };

  const receiptFunction = async (orderResult, payment) => {
    try {
      if (orderResult.orderCreated) {
        await clover.printReceipt(payment.data, orderResult.order.data);
        if (cart?.is_exchange_order) {
          await sendExchangeEmail(orderResult.order.data);
        }
        updateProcessedCart(cart.id);
        await clearCart();
      } else {
        throw new Error('Order creation failed.');
      }
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
      throw new Error('Something went wrong while processing your receipt.');
    }
  };

  const paymentResponse200 = async (payment) => {
    let orderResult = {};
    try {
      orderResult = await processOrder(payment);
      await receiptFunction(orderResult, payment);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handlePaymentResponse = async (payment) => {
    switch (payment.status) {
      case 200:
        await paymentResponse200(payment);

        break;
      case 209: {
        setTimeout(() => {
          clover.displayWelcome();
        }, 3000);
        break;
      }

      case 400: {
        setTimeout(() => {
          clover.displayWelcome();
        }, 3000);
        break;
      }
      case 401: {
        break;
      }
      case 415: {
        break;
      }
      case 500: {
        break;
      }
      case 501: {
        break;
      }
      case 503: {
        break;
      }
      case 504: {
        break;
      }
      default: {
        // setTimeout(() => {
        //   clover.displayWelcome();
        // }, 3000);
        break;
      }
    }
  };

  const handleCashPayment = async () => {
    try {
      setLoading(false);
      setPaymentResponse({ status: 200 });
      setOpen(true);
      let order;

      if (cart.is_exchange_order) {
        const params = {
          cartId: cart?.id,
          paymentResponse: null,
          transactionType: 'refund',
        };
        order = await exchanges.createExchangeOrder(params);
        // send exchange email
        await sendExchangeEmail(order.data, checkRefund);
      } else {
        order = await orders.createCashOrder(cart?.id);
      }
      setOpen(false);
      setOrderResponse(order.data);

      setOrderOpen(true);
      await clearCart();
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
    }
  };

  const handleRetry = () => {
    setOpen(true);
    if (financialCalculators.getTotalPrice(cart) > 0) {
      handlePaymentResponse(paymentResponse);
    } else {
      handleCashPayment();
    }
  };

  const sendPaymentRequest = async (manualEntry = false) => {
    try {
      const totalPrice = financialCalculators.getTotalPrice(cart);
      if (totalPrice === 0) {
        handleCashPayment();
        return;
      }
      if (cart.id && totalPrice !== 0) {
        setOpen(true);
        setLoading(true);
        const paymentData = { cartId: cart.id, paymentTotal: totalPrice, manualEntry };
        const result = await clover.paymentRequest(paymentData);
        setLoading(false);
        setPaymentResponse(result);
        handlePaymentResponse(result);
        setLoading(false);
      } else {
        throw new Error(copyText.Cart.CartTools.paymentFailed);
      }
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
    }
  };

  const cloverValidation = () => {
    const cloverDevice = cloverUtils.getSessionCloverDevice();
    setCheckoutDisabled(!cloverDevice || cloverDevice === 'select');
  };

  useEffect(() => {
    cloverValidation();
    window.addEventListener('storage', cloverValidation);
    return () => window.removeEventListener('storage', cloverValidation);
  }, []);

  return (
    <>
      <PaymentResponse
        response={paymentResponse}
        open={open}
        loading={loading}
        resendPaymentRequest={() => sendPaymentRequest(true)}
        closeModal={() => setOpen(false)}
      />
      {orderResponse && (
        <ReceiptModal
          open={orderOpen}
          order={orderResponse}
          loadOrder={loadOrder}
          closeModal={() => setOrderOpen(false)}
        />
      )}
      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 3,
          textTransform: 'none',
          width: '100%',
          borderRadius,
          height,
        }}
        onClick={() => sendPaymentRequest(false)}
        disabled={!cart?.shippingAddress || checkoutDisabled}
      >
        <Typography variant="h3" component="span" color="white">
          {copyText.Cart.CheckoutButtons.proceed}
        </Typography>
      </Button>
      <Box sx={{ mt: 1 }} display="flex" justifyContent="center">
        <Button
          onClick={() => sendPaymentRequest(true)}
          disabled={!cart?.shippingAddress || checkoutDisabled}
        >
          {copyText.Cart.CheckoutButtons.affirm}
        </Button>
      </Box>
      <ClearCart />
      <OrderFailureDialog
        errorMessage={errorMessage}
        open={createOrderFailure}
        onClose={() => setCreateOrderFailure(false)}
        onRetry={handleRetry}
      />
    </>
  );
};

export default CheckoutButtons;

CheckoutButtons.propTypes = {
  checkRefund: bool,
};

CheckoutButtons.defaultProps = {
  checkRefund: false,
};
