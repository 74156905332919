import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  Typography,
} from '@mui/material';
import { copyText } from 'language';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';

const RefundModal = ({ show, setShow, handleRetry, loading, refundStatus }) => {
  const urlParams = useParams();
  const getTitle = () => {
    if (loading) {
      return copyText.Orders.Refunds.processingRefund;
    }
    if (refundStatus.status === 'FAILED') {
      return copyText.Orders.Refunds.refundFailed;
    }
    if (refundStatus.status === 200) {
      return copyText.Orders.Refunds.transactionSummary;
    }
    return '';
  };

  const getContent = () => {
    if (loading) {
      return (
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            width: '50vw',
            height: '25vh',
          }}
        >
          <CircularProgress size={75} color="secondary" />
        </DialogContent>
      );
    }
    if (refundStatus.status === 'FAILED') {
      return (
        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            {copyText.App.reason}:{' '}
            {refundStatus?.error?.response?.data?.error || refundStatus.message}
          </Typography>
          <Typography>{copyText.Orders.Refunds.tryAgain}</Typography>
        </DialogContent>
      );
    }
    if (refundStatus.status === 200) {
      const result = refundStatus.data.result;
      return (
        <DialogContent>
          <Typography sx={{ fontWeight: 600, mt: 3 }}>
            {copyText.Orders.Refunds.successfullyRefunded}:
          </Typography>
          {result.payments_refunded?.length === 0 && <Typography>{copyText.App.none}</Typography>}
          {result.payments_refunded.map((payment) => {
            return <ListItem>{payment.payment_id}</ListItem>;
          })}
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography sx={{ fontWeight: 600 }}>
            {copyText.Orders.Refunds.failedToRefund}:
          </Typography>
          {result.payments_not_refunded?.length === 0 && (
            <Typography>{copyText.App.none}</Typography>
          )}
          {result.payments_not_refunded.map((payment) => {
            return (
              <ListItem
                key={payment.payment_id}
                sx={{ display: 'flex', pl: 0, flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Typography>
                  {copyText.Orders.Refunds.paymentId}: {payment.payment_id}
                </Typography>
                <Typography>
                  {copyText.App.reason}: {payment.reason}
                </Typography>
              </ListItem>
            );
          })}
          <Typography fontWeight={600}>
            {copyText.Orders.Refunds.refundFailedContactCCare}
          </Typography>
        </DialogContent>
      );
    }
    return '';
  };

  const handleClose = () => {
    setShow(false);
  };

  const getActions = () => {
    if (loading) {
      return <Button onClick={handleClose}>{copyText.App.cancel}</Button>;
    }
    if (refundStatus.status === 200) {
      return (
        <>
          <Button variant="outlined" sx={{ mr: 3 }} onClick={handleClose}>
            {copyText.Orders.Refunds.createAnotherRefund}
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 3 }}
            component={RouterLink}
            to={`/order/${urlParams.orderId}`}
          >
            {copyText.Orders.Refunds.backToOrder}
          </Button>
        </>
      );
    }
    if (refundStatus.status === 'FAILED') {
      return (
        <>
          <Button variant="outlined" sx={{ mr: 3 }} onClick={handleClose}>
            {copyText.App.close}
          </Button>
          <Button variant="contained" sx={{ mr: 1 }} onClick={handleRetry}>
            {copyText.App.retry}
          </Button>
        </>
      );
    }

    return (
      <Button variant="outlined" sx={{ mr: 3 }} onClick={handleClose}>
        {copyText.App.close}
      </Button>
    );
  };
  return (
    <Dialog open={show} sx={{ display: 'flex', justifyContent: 'center' }}>
      <DialogTitle sx={{ backgroundColor: 'lightGrayBG' }}>{getTitle()}</DialogTitle>
      {getContent()}
      <DialogActions sx={{ backgroundColor: 'lightGrayBG', p: 2 }}>{getActions()}</DialogActions>
    </Dialog>
  );
};

RefundModal.propTypes = {
  show: bool.isRequired,
  setShow: func.isRequired,
  handleRetry: func.isRequired,
  loading: bool.isRequired,
  refundStatus: shape({
    status: oneOfType([string, number]),
    message: string,
    data: shape({
      result: shape({
        payments_refunded: arrayOf(shape({})),
        payments_not_refunded: arrayOf(shape({})),
      }),
    }),
    error: shape({
      response: shape({
        data: shape({
          error: string,
        }),
      }),
    }),
  }),
};

RefundModal.defaultProps = {
  refundStatus: null,
};

export default RefundModal;
