import { Icon, MenuItem, Typography } from '@mui/material';
import React from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { copyText } from 'language';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CartHistorySearch = () => {
  const history = useHistory();
  const toCartHistory = () => {
    history.push('cart-history');
  };
  return (
    <MenuItem onClick={toCartHistory}>
      <Icon sx={{ mr: 1 }}>
        <ManageSearchIcon />
      </Icon>
      <Typography>{copyText.Cart.CartTools.CartHistory.history}</Typography>
    </MenuItem>
  );
};

export default CartHistorySearch;
