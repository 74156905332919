import React, { useState } from 'react';
import { Card, Divider } from '@mui/material';
import { useCart } from 'contexts';
import OrderTotal from 'components/OrderTotal/OrderTotal';
import { canDoSplitTransactions } from 'utils/experimentalFeatureTools';
import CartTotals from './components/CartTotals/CartTotals';
import PromoCode from './components/PromoCode/PromoCode';
import CheckoutButtonNew from './components/CheckoutButtons/CheckoutButtonNEW';
import CheckoutButtons from './components/CheckoutButtons/CheckoutButtons';

const CartFinancials = () => {
  const [checkRefund, setCheckRefund] = useState(false);
  const cart = useCart();
  return (
    <Card
      square
      variant="contained"
      sx={{
        backgroundColor: 'lightGrayBG',
        display: 'flex',
        flexDirection: 'column',
        pl: 3,
        pt: 1,
        pr: 3,
      }}
    >
      <CartTotals cart={cart} />
      <Divider color="divider" sx={{ mb: 1 }} />
      <PromoCode />
      <Divider color="divider" sx={{ mb: 2 }} />
      <OrderTotal cart={cart} checkRefund={checkRefund} setCheckRefund={setCheckRefund} />
      {canDoSplitTransactions() && <CheckoutButtonNew />}
      <CheckoutButtons checkRefund={checkRefund} />
    </Card>
  );
};

export default CartFinancials;
