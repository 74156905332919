import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { getErrorsFromStorage } from 'utils/errorUtils';
import { copyText } from 'language';

const ErrorLogs = () => {
  const [errorLogs, setErrorLogs] = useState([]);

  const getErrorLogs = () => {
    const logs = getErrorsFromStorage();
    return logs;
  };

  const populateErrorLogs = () => {
    const logs = getErrorLogs();
    setErrorLogs(logs);
  };

  useEffect(() => {
    populateErrorLogs();
  }, []);

  return (
    <Grid container sx={{ height: '100vh', p: 2 }}>
      <Grid item mt={{ xs: 8 }} sm={12}>
        {errorLogs?.length > 0 && (
          <>
            <Typography variant="h1">{copyText.ErrorLogs.heading}</Typography>
            <Typography variant="body">{copyText.ErrorLogs.instructions}</Typography>
            <Grid sx={{ overflow: 'scroll' }}>
              {errorLogs.map((entry, ind) => {
                const key = ind + 1;
                return (
                  <Typography component="p" key={key} sx={{ fontSize: '9px' }}>
                    {entry}
                  </Typography>
                );
              })}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ErrorLogs;
