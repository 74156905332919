import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { copyText } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import { arrayOf, objectOf, shape, string } from 'prop-types';

const RefundDisplay = ({ orderPayments, orderNumber }) => {
  const [totalRefundAmount, setTotalRefundAmount] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [totalTransferAmount, setTotalTransferAmount] = useState(0);
  const getTransactions = (payments) => {
    const transactionArray = [];
    payments?.forEach((payment) => {
      transactionArray.push(...payment.transactions);
    });
    return transactionArray;
  };
  const findRefunds = (transactions) => {
    const successfulRefunds = transactions?.filter((transaction) => {
      return (
        transaction.type === 'Refund' &&
        transaction.state === 'Success' &&
        transaction.custom?.fields?.type !== 'Transfer'
      );
    });
    return successfulRefunds;
  };

  /**
   * Finds and filters transactions to identify transferred funds TO a specific order number.
   *
   * @param {Array} transactions - An array of transactions to be filtered.
   * @returns {Array} - An array containing filtered transactions representing transfers.
   */
  const findTransfers = (transactions) => {
    const transfers = transactions?.filter((transaction) => {
      return (
        transaction.custom?.fields?.type === 'Transfer' &&
        transaction.type === 'Refund' &&
        transaction.custom?.fields?.destination_order_number !== orderNumber
      );
    });
    return transfers;
  };

  const findPayments = (transactions) => {
    const successfulRefunds = transactions?.filter((transaction) => {
      return transaction.type === 'Charge' && transaction.state === 'Success';
    });
    return successfulRefunds;
  };
  /**
   * Calculates the total transfer amount from a list of payments.
   *
   * @param {Array} payments - An array of payments containing transaction data.
   * @returns {number} - The total transfer amount in cents.
   */
  const calculateTransferAmount = (payments) => {
    const transactions = getTransactions(payments);

    const transfers = findTransfers(transactions);
    const transferAmount = transfers?.reduce((acc, transfer) => {
      return acc + (transfer.amount?.centAmount || 0);
    }, 0);
    setTotalTransferAmount(convertCentToDollar(transferAmount));
    return transferAmount;
  };

  const calculateRefundAmount = (payments) => {
    const transactions = getTransactions(payments);
    const refunds = findRefunds(transactions);
    const refundAmount = refunds?.reduce((acc, refund) => {
      return acc + refund.amount.centAmount;
    }, 0);
    setTotalRefundAmount(convertCentToDollar(refundAmount));
    return refundAmount;
  };
  const calculateAvailableFunds = (payments) => {
    const transactions = getTransactions(payments);
    const successfulPayments = findPayments(transactions);
    const availableAmount = successfulPayments?.reduce((acc, payment) => {
      return acc + payment.amount.centAmount;
    }, 0);
    const availableMinusRefundsAndTransfers =
      availableAmount - calculateRefundAmount(payments) - calculateTransferAmount(payments);
    setTotalAvailable(convertCentToDollar(availableMinusRefundsAndTransfers));
  };
  useEffect(() => {
    calculateRefundAmount(orderPayments);
    calculateAvailableFunds(orderPayments);
    calculateTransferAmount(orderPayments);
  }, [orderPayments]);

  return (
    <Box key="refund_display" sx={{ backgroundColor: 'lightGrayBG', mt: 1, p: 2 }}>
      <Typography variant="sub_title">{copyText.Orders.RefundInfo.refunds}</Typography>
      <Typography>{`${copyText.Orders.RefundInfo.refunded}: ${totalRefundAmount}`}</Typography>
      <Typography>{`${copyText.Orders.RefundInfo.transferred}: ${totalTransferAmount}`}</Typography>
      <Typography>{`${copyText.Orders.RefundInfo.available}: ${totalAvailable}`}</Typography>
    </Box>
  );
};

export default RefundDisplay;

RefundDisplay.propTypes = {
  orderPayments: arrayOf(
    shape({
      transactions: arrayOf(objectOf(string)),
    }),
  ),
  orderNumber: string,
};

RefundDisplay.defaultProps = {
  orderPayments: [],
  orderNumber: '',
};
