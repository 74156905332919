import React, { useState, useEffect } from 'react';
import {
  Box,
  Tooltip,
  Typography,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import convertCentToDollar from 'utils/convertCentToDollar';
import { copyText } from 'language';
import { bool, func, number, shape } from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import FinanceListItem from 'components/FinanceListItem';

const OrderTotal = ({ cart, showOrderTotals, checkRefund, setCheckRefund }) => {
  const [totalPrice, setTotalPrice] = useState('$0.00');
  const [shippingTotals, setShippingTotals] = useState('$0.00');
  const [taxTotals, setTaxTotals] = useState('$0.00');
  const [recyclingFeeTotal, setRecyclingFeeTotal] = useState('$0.00');
  const [subTotal, setSubTotal] = useState('$0.00');
  const [refundTotal, setRefundTotal] = useState('');
  const [showRefund, setShowRefund] = useState(false);

  const populateExchangeRefund = () => {
    if (cart?.is_exchange_order && financialCalculators.getExchangeRefundTotal(cart) < 0) {
      setRefundTotal(
        convertCentToDollar(Math.abs(financialCalculators.getExchangeRefundTotal(cart))),
      );
      setShowRefund(true);
    } else {
      setShowRefund(false);
    }
  };

  useEffect(() => {
    setTotalPrice(convertCentToDollar(financialCalculators.getTotalPrice(cart)));
    setSubTotal(convertCentToDollar(financialCalculators.getSubtotal(cart)));
    setTaxTotals(convertCentToDollar(financialCalculators.getTax(cart)));
    populateExchangeRefund();
    setShippingTotals(convertCentToDollar(financialCalculators.getShippingTotals(cart)));
    setRecyclingFeeTotal(convertCentToDollar(financialCalculators.getRecyclingFee(cart)));
  }, [cart, cart?.exchange_order_line_items]);

  return (
    <List>
      <FinanceListItem label={copyText.Cart.OrderTotal.subTotal} value={subTotal} boldLabel />
      {showOrderTotals && (
        <>
          <FinanceListItem label={copyText.Cart.CartTools.shipping} value={shippingTotals} />
          <FinanceListItem label={copyText.Cart.CartTools.tax} value={taxTotals} />

          {recyclingFeeTotal !== '$0.00' && (
            <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
              <Box display="flex" alignItems="center">
                <Typography variant="list_item">{copyText.Cart.CartTools.recyclingFee}</Typography>
                <Tooltip
                  sx={{ ml: 1 }}
                  disableFocusListener
                  title={copyText.Cart.CartTools.recyclingFeeExplanation}
                >
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
              <Typography variant="list_item" align="right">
                {recyclingFeeTotal}
              </Typography>
            </ListItem>
          )}
          <FinanceListItem
            label={copyText.Cart.OrderTotal.total}
            value={totalPrice}
            boldLabel
            boldValue
          />

          {!cart?.shippingAddress && (
            <Typography
              variant="subtitle"
              sx={{ fontWeight: 'semi-bold', fontSize: 12 }}
              color="error"
            >
              {copyText.Cart.CartTools.shippingAddressRequired}
            </Typography>
          )}
          {cart?.is_exchange_order && showRefund && (
            <>
              <FinanceListItem
                label={copyText.Cart.OrderTotal.refundTotal}
                value={`(${refundTotal})`}
                isPromo
              />
              <FormControlLabel
                control={
                  <Checkbox checked={checkRefund} onChange={() => setCheckRefund(!checkRefund)} />
                }
                label={copyText.Cart.OrderTotal.checkRefund}
              />
              {checkRefund && (
                <Typography>{copyText.Cart.OrderTotal.checkRefundMessage}</Typography>
              )}
            </>
          )}
        </>
      )}
    </List>
  );
};

OrderTotal.propTypes = {
  cart: shape({
    giftCards: shape({
      amount: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  showOrderTotals: bool,
  checkRefund: bool,
  setCheckRefund: func.isRequired,
};

OrderTotal.defaultProps = {
  showOrderTotals: true,
  checkRefund: false,
};

export default OrderTotal;
