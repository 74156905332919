import React, { useState, useEffect } from 'react';
import {
  TableCell,
  TableRow,
  TextField,
  IconButton,
  Collapse,
  List,
  ListSubheader,
  ListItem,
  Typography,
  Grid,
} from '@mui/material';
import { shape, string, number, arrayOf, func } from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { lang, copyText } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';

const ReturnRow = (props) => {
  const {
    row,
    selectedOrder,
    orderRefunds,
    quantityErrorSku,
    setQuantityErrorSku,
    returnItems,
    setReturnItems,
    setDisable,
  } = props;
  const returnInfo = selectedOrder.returnInfo;
  const [open, setOpen] = useState(false);
  const [itemReturnArray, setItemReturnArray] = useState();

  const getAvailableQuantity = (order, lineItem) => {
    if (order.returnInfo.length) {
      const returnArray = order.returnInfo.map((returnObj) => {
        const returnItem = returnObj.items.filter((item) => item.lineItemId === lineItem.id);
        return returnItem[0] || { quantity: 0 };
      });
      const returnedItems = returnArray.reduce((acc, item) => acc + item.quantity, 0);
      return lineItem.quantity - returnedItems;
    }
    return lineItem.quantity;
  };

  const removeFromReturnItems = (sku) => {
    const items = returnItems.filter((item) => item.sku !== sku);
    setReturnItems(items);
    return items;
  };

  const addToTotalRefund = (returnObj) => {
    const items = returnItems.slice(); // Create a copy of the array
    const existingItem = items.find((returnItem) => returnItem.sku === returnObj.sku);

    if (existingItem) {
      // Update the existing item in the new array
      const index = items.indexOf(existingItem);
      items[index] = returnObj;
    } else {
      // Push the new item to the new array
      items.push(returnObj);
    }

    setReturnItems(items);
    return items;
  };

  const validateButton = (items) => {
    const numItems = items.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0);
    setDisable(numItems === 0);
  };

  const handleInput = (item, e) => {
    const { sku } = item.variant;
    let items = [...returnItems];
    const availableQuantity = getAvailableQuantity(selectedOrder, item);
    const inputValue = e.target.value;

    if (inputValue === '' || inputValue === '0') {
      // When value is empty or 0, remove item from returnItems array.
      setQuantityErrorSku('');
      items = removeFromReturnItems(sku);
    } else if (inputValue <= availableQuantity && inputValue >= 0) {
      // Validate that value is not larger than quantity on order and also greater than or equal to 0.
      setQuantityErrorSku('');
      const itemObj = { sku, quantity: inputValue };
      items = addToTotalRefund(itemObj);
    } else {
      // If value is not correct, report an error and reset the wrong value.
      setQuantityErrorSku(sku);
      e.target.value = '';
    }
    validateButton(items);
  };

  const getItemReturnInfo = (item) => {
    const itemReturnDetails = returnInfo?.map((returns) => {
      return returns.items.filter((returnLineItems) => {
        return returnLineItems.lineItemId === item.id;
      });
    });
    setItemReturnArray(itemReturnDetails?.flat());
  };

  const getRefundAmount = (returnObj) => {
    const refundAmount = orderRefunds.reduce((acc, refund) => {
      if (
        refund?.line_item_id === returnObj.lineItemId &&
        refund?.return_item_id === returnObj.id
      ) {
        return acc + refund.amount;
      }
      return acc;
    }, 0);
    return refundAmount;
  };
  // This sets the error on the inputs so only the given sku turns red
  const showQuantityError = (sku) => {
    return quantityErrorSku === sku;
  };

  useEffect(() => {
    getItemReturnInfo(row);
  }, []);

  return (
    <>
      <TableRow key={row.id} value={row} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{row.name[lang]}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{row.variant.sku}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          {convertCentToDollar(row.taxedPrice.totalGross.centAmount)}
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          {getAvailableQuantity(selectedOrder, row)}
        </TableCell>
        <TableCell align="center" sx={{ maxWidth: 50, alignContent: 'center' }}>
          {/* Quantity to Return */}
          <TextField
            id={`${row.variant.sku}-return-quantity`}
            data-testid="number-input"
            InputProps={{
              min: 0,
              max: row.quantity,
            }}
            error={showQuantityError(row.variant.sku)}
            type="number"
            placeholder="0"
            sx={{ width: 100 }}
            onChange={(e) => handleInput(row, e)}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: 2 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              dense
              disablePadding
              subheader={
                <ListSubheader color="primary" sx={{ fontWeight: 'bold' }}>
                  {!itemReturnArray?.length
                    ? copyText.Orders.Returns.noPreviousReturns
                    : copyText.Orders.Returns.previousReturns}
                </ListSubheader>
              }
            >
              {!!itemReturnArray?.length &&
                itemReturnArray?.map((returnData) => {
                  return (
                    <ListItem key={returnData.id}>
                      <Grid container>
                        <Grid item>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.date}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {new Date(returnData.createdAt).toLocaleString()}
                          </Typography>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.quantity}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData.quantity}
                          </Typography>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.refundAmount}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            ${getRefundAmount(returnData)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.shippingStatus}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData.shipmentState || copyText.Orders.Returns.pending}
                          </Typography>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.refundStatus}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData.paymentState || copyText.Orders.Returns.pending}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ReturnRow.propTypes = {
  row: shape({
    id: string,
    quantity: number,
    variant: shape({ sku: string }),
    name: shape({ 'en-US': string }),
    taxedPrice: shape({ totalGross: shape({ centAmount: number }) }),
  }),
  selectedOrder: shape({
    returnInfo: arrayOf(
      shape({
        items: arrayOf(
          shape({
            id: string,
            lastModifiedAt: string,
            lineItemId: string,
            paymentState: string,
            shipmentState: string,
            quantity: number,
          }),
        ),
      }),
    ),
  }),
  orderRefunds: arrayOf(
    shape({
      line_item_id: string,
    }),
  ),
  quantityErrorSku: string,
  setQuantityErrorSku: func.isRequired,
  returnItems: arrayOf(shape({ sku: string })),
  setReturnItems: func.isRequired,
  setDisable: func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selectedOrder: {},
  orderRefunds: [],
  quantityErrorSku: '',
  returnItems: [],
};
export default ReturnRow;
