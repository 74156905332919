import { React, useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';

import { copyText } from 'language';
import { useShopProducts } from 'contexts';

import CategoryCard from 'components/CategoryCard/CategoryCard';
import Page404 from 'views/Page404/Page404';

import SkeletonGrid from '../../components/SkeletonGrid/SkeletonGrid';
import ShopHeader from '../../components/ShopHeader/ShopHeader';

const Products = () => {
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);
  const { categoryId } = useParams();
  const shopProducts = useShopProducts();

  const getProducts = () => {
    if (shopProducts) {
      setProducts(shopProducts[categoryId]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [shopProducts]);

  if (!products && loading && !shopProducts) {
    return (
      <>
        <ShopHeader />
        <Grid container spacing={4} sx={{ justifyContent: 'space-evenly' }}>
          <SkeletonGrid numItems={6} />
        </Grid>
      </>
    );
  }

  if (!products && !loading) {
    return <Page404 error={copyText.Shop.Products.error} />;
  }

  if (products) {
    return (
      <>
        <ShopHeader />
        <Grid container spacing={4} sx={{ justifyContent: 'space-evenly' }}>
          {products?.map(
            (product) =>
              product && (
                <Grid key={product.id} item>
                  <Link
                    component={RouterLink}
                    underline="none"
                    to={{
                      pathname: `/category/${categoryId}/product/${product.id}`,
                    }}
                  >
                    <CategoryCard
                      imgUrl={product.masterVariant?.images[0]?.url}
                      title={product.name?.en || product.name['en-US']}
                      imgAlt={`
                        ${product.name?.en || product.name['en-US']}
                        ${' '}${copyText.Shop.Products.imgAlt}
                      `}
                    />
                  </Link>
                </Grid>
              ),
          )}
        </Grid>
      </>
    );
  }
  return null;
};

export default Products;
