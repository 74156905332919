/* eslint-disable react/forbid-prop-types */
import { Button, Dialog, DialogContent, Grid, DialogActions, DialogTitle } from '@mui/material';
import { copyText } from 'language';
import { object, bool, func, array } from 'prop-types';
import ReturnForm from './ReturnForm';

const ReturnModal = (props) => {
  const { disableStart, returnItems, orderInfo, displayModal, setDisplayModal } = props;
  // const [modalOpen, setModalOpen] = useState(false);

  // SUNSET: Get this data preferably from an API endpoint so it is always up-to-date
  // Opens the modal containing the iframe
  const openModal = () => {
    setDisplayModal(true);
  };
  // Closes the modal containing the iframe
  const closeModal = () => {
    setDisplayModal(false);
  };

  const displayHidden = () => {
    const qualtricsReturns = document.getElementById('qualtrics-returns').contentWindow;
    const message = {
      event: 'show',
    };
    qualtricsReturns.postMessage(JSON.stringify(message), '*');
  };

  return (
    <>
      <Button
        disabled={disableStart}
        variant="contained"
        color="primary"
        onClick={openModal}
        data-testid="start-button"
      >
        {copyText.Orders.Returns.startReturn}
      </Button>
      <Dialog
        fullWidth
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{copyText.Orders.Returns.dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item sm={12}>
              <ReturnForm returnItems={returnItems} orderInfo={orderInfo} closeModal={closeModal} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={displayHidden}>
            Review
          </Button>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            {copyText.Orders.Returns.close}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReturnModal;

ReturnModal.propTypes = {
  disableStart: bool,
  returnItems: array,
  orderInfo: object,
  displayModal: bool,
  setDisplayModal: func.isRequired,
};

ReturnModal.defaultProps = {
  disableStart: false,
  returnItems: {},
  orderInfo: {},
  displayModal: false,
};
