const getOktaData = () => {
  return JSON.parse(sessionStorage.getItem('okta-token-storage'));
};

const getDevelopers = () => {
  return [
    'adrian.ri@purple.com',
    'todd.f@purple.com',
    'joseph.d@purple.com',
    'jeffery.b@purple.com',
    'curtis.je@purple.com',
  ];
};

const getExperimentalUsers = () => {
  return [
    'curtis.je@purple.com',
    'adrian.ri@purple.com',
    'todd.f@purple.com',
    'joseph.d@purple.com',
    'jeffery.b@purple.com',
    'gregor.g@purple.com',
    'matthew.z@purple.com',
    'bronwyn.h@purple.com',
    'jax.m@purple.com',
    'r-kaylee.o@purple.com',
  ];
};

// --- Template for hiding features behind experiment group ---
/**
  const getExperimentalUsers = () => {
    return [
      'curtis.je@purple.com',
      'adrian.ri@purple.com',
      'todd.f@purple.com',
      'joseph.d@purple.com',
      'jeffery.b@purple.com',
      'gregor.g@purple.com',
      'matthew.z@purple.com',
      'bronwyn.h@purple.com',
      'jax.m@purple.com',
      'r-kaylee.o@purple.com',
    ];
  };

  const canEXPERIMENT_NAME = () => {
    const currentUser = getOktaData();
    const userEmail = currentUser?.accessToken?.claims.sub;
    const expUsers = getExperimentalUsers();

    const userInList = expUsers.includes(userEmail);
    return userInList;
  };
 */

const canExchange = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const expUsers = getExperimentalUsers();

  const userInList = expUsers.includes(userEmail);
  return userInList;
};

export const canDoSplitTransactions = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const developers = getDevelopers();

  const userInList = developers.includes(userEmail);
  return userInList;
};

export default {
  canExchange,
};
