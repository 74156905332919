import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  IconButton,
  Snackbar,
} from '@mui/material';
import copyText from 'language/enUS';
import { lang } from 'language';
import { useEffect, useState } from 'react';
import storeLocation from 'utils/storeLocation';
import convertCentToDollar from 'utils/convertCentToDollar';
import { useOktaAuth } from '@okta/okta-react';
import logo from 'media/images/purple-logo.svg';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import clover from 'dataAccess/api/clover.ts';
import { formatCreatedAtTime } from 'utils/time';
import TextLoader from 'components/TextLoader';
import CloseIcon from '@mui/icons-material/Close';
import PickListItem from './components/PickListItem';
import ExchangeItems from './components/ExchangeItems';

const ReceiptModal = ({ open, order, closeModal, showHeader, loadOrder }) => {
  const [address, setAddress] = useState('');
  const [storeName, setStoreName] = useState('');
  const [shippingTotals, setShippingTotals] = useState('');
  const [taxTotals, setTaxTotals] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [refundAmount, setRefundAmount] = useState(null);
  const [promoCodeDiscounts, setPromoCodeDiscounts] = useState([]);
  const [recyclingFeeTotal, setRecyclingFeeTotal] = useState(null);
  const [exchangeAmount, setExchangeAmount] = useState(0);
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState('');
  const { oktaAuth } = useOktaAuth();

  const getAgent = () => {
    const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
    if (name) {
      setAgentName(name);
    }
  };
  const receiptStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    mt: 2,
    p: 0,
  };
  const getShippingTotals = () => {
    let total = 0;
    order?.customLineItems?.forEach((lineItem) => {
      if (lineItem?.name[lang] !== 'Recycling Fee') {
        total += lineItem?.totalPrice?.centAmount || 0;
      }
    });
    setShippingTotals(convertCentToDollar(total));
    return total;
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setMessage('');
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const printThermalReceipt = async () => {
    try {
      setLoading(true);
      await clover.printReceipt(null, order);
      setMessage(copyText.Orders.Actions.printReceiptSuccess);
    } catch (error) {
      setMessage(copyText.Orders.Actions.printReceiptFailure);
    } finally {
      setOpenSnackbar(true);

      setLoading(false);
    }
  };

  const getRecyclingFee = () => {
    let recyclingFee = 0;
    order?.customLineItems?.forEach((lineItem) => {
      if (lineItem?.name[lang] === 'Recycling Fee') {
        recyclingFee += lineItem?.taxedPrice?.totalGross?.centAmount || 0;
      }
    });
    setRecyclingFeeTotal(convertCentToDollar(recyclingFee));
    return recyclingFee;
  };

  const getGiftCardAmount = (giftCard) => {
    return convertCentToDollar(giftCard?.amount?.centAmount ?? 0);
  };

  const getGiftCardNumber = (giftCard) => {
    if (giftCard && giftCard.giftCardNumber) {
      const { giftCardNumber } = giftCard;
      return giftCardNumber.slice(giftCardNumber.length - 4);
    }
    return '';
  };

  const getLineItemPrice = (lineItem) => {
    const lineItemPrice = lineItem?.totalPrice?.centAmount ?? 0;
    let discounts = [];
    if (lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json) {
      discounts = JSON.parse(lineItem.custom.fields.discounts_json);
    }
    const sumWithInitial = discounts.reduce(
      (accumulator, discount) => accumulator + (discount?.cent_amount ?? 0),
      lineItemPrice,
    );
    return convertCentToDollar(sumWithInitial);
  };

  const getTax = () => {
    const totalGross = order?.taxedPrice?.totalGross?.centAmount;
    const totalNet = order?.taxedPrice?.totalNet?.centAmount;
    setTaxTotals(convertCentToDollar(totalGross - totalNet || 0));
  };

  const getShippingMethod = (lineItem) => {
    const result = order?.customLineItems?.filter((customLineItem) => {
      return customLineItem?.custom?.fields?.lineItemsIds?.includes(lineItem.id);
    });
    return result[0]?.custom?.fields?.netsuite_shipping_name ?? '';
  };

  const getGiftCardTotals = () => {
    let giftCards = 0;

    if (order && order.giftCards && Array.isArray(order.giftCards)) {
      giftCards = order?.giftCards.reduce((acc, next) => {
        const amount = next?.amount?.centAmount ?? 0;
        return acc + amount;
      }, 0);
    }

    return giftCards;
  };

  const getPromoCodeDiscounts = () => {
    let totalPromoCodeDiscounts = [];
    order?.lineItems?.forEach((lineItem) => {
      if (lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json) {
        const parsedPromoCodeDiscounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
        if (parsedPromoCodeDiscounts) {
          totalPromoCodeDiscounts = parsedPromoCodeDiscounts.map((discount) => {
            return {
              id: discount?.id ?? '',
              centAmount: convertCentToDollar(discount?.cent_amount) ?? '',
              couponCode: discount?.coupon_code ?? '',
              method: discount?.method ?? '',
              displayName: discount?.display_name ?? '',
            };
          });
        }
      }
    });
    setPromoCodeDiscounts(totalPromoCodeDiscounts);
  };

  const calculateExchangeAmount = () => {
    let exchangeTotalAmount = 0;
    if (order?.custom?.fields?.is_exchange_order || order.is_exchange_order) {
      const exchangeLineItems = JSON.parse(
        order?.custom?.fields?.exchange_order_line_items || order.exchange_order_line_items,
      );
      exchangeTotalAmount = exchangeLineItems.reduce((acc, item) => {
        if (item?.exchangeValue?.centAmount) {
          return item.exchangeValue.centAmount + acc;
        }
        return acc;
      }, 0);
    }

    setExchangeAmount(exchangeTotalAmount);
  };

  const calculateTotalPrice = (orderDetails) => {
    const giftCards = getGiftCardTotals();
    const price = orderDetails?.taxedPrice?.totalGross?.centAmount ?? 0;
    return price - giftCards - exchangeAmount;
  };

  const populateTotalPrice = () => {
    const total = calculateTotalPrice(order);
    if (total < 0) {
      setTotalPrice(convertCentToDollar(0));
      setRefundAmount(convertCentToDollar(Math.abs(total)));
    } else {
      setTotalPrice(convertCentToDollar(total));
    }
  };

  const calculateSubtotal = () => {
    const giftCards = getGiftCardTotals();
    const price = order?.taxedPrice?.totalGross?.centAmount ?? 0;
    return convertCentToDollar(price - giftCards);
  };

  const populateStoreData = async () => {
    const hasStoreKey = order && order.store && order.store?.key;
    if (hasStoreKey) {
      const storeData = await storeLocation.getStoreByKey(order?.store?.key);
      setAddress(storeData?.supplyChannels[0]?.obj?.address ?? '');
      setStoreName(storeData?.name[lang] ?? '');
    } else {
      setAddress(storeLocation.getStoreAddress());
      setStoreName(storeLocation.getLocationStorage()?.name[lang] ?? '');
    }
  };

  const populateTime = () => {
    setDate(formatCreatedAtTime(order.createdAt));
  };

  useEffect(() => {
    populateStoreData();
    getPromoCodeDiscounts();
    getShippingTotals();
    getTax();
    calculateExchangeAmount();
    populateTotalPrice();
    getAgent();
    getRecyclingFee();
    populateTime();
  }, [order]);

  useEffect(() => {
    populateTotalPrice();
  }, [exchangeAmount]);
  return (
    <Dialog scroll="body" open={open} fullWidth>
      {showHeader && (
        <DialogTitle sx={{ displayPrint: 'none' }}>
          {copyText.Cart.PaymentResponse.orderCreated}!
        </DialogTitle>
      )}
      {!showHeader && <DialogTitle sx={{ mb: 3, displayPrint: 'none', backgroundColor: 'none' }} />}
      <DialogTitle sx={{ display: 'none', displayPrint: 'block' }}>
        <img src={logo} alt={copyText.Header.logo.alt} style={{ height: '3rem' }} />
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6">{storeName ?? ''}</Typography>
        <Typography>{address?.streetName ?? ''}</Typography>
        <Typography>{address?.streetNumber ?? ''}</Typography>
        <Typography>
          {`${address?.city ?? ''},
                ${address?.state ?? ''}`}
        </Typography>
        <Typography marginTop={4} variant="h6">
          {copyText.App.orderNumber} {order?.orderNumber}
        </Typography>
        <List>
          {(order.is_exchange_order || order?.custom?.fields?.is_exchange_order) && (
            <>
              <Divider sx={{ mt: 2 }} />
              <ExchangeItems order={order} loadOrder={loadOrder} />
            </>
          )}
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="left">
              {copyText.Cart.receipt.purchaseItems}
            </Typography>
            <Typography variant="p" component="strong" align="right">
              {copyText.App.price}
            </Typography>
          </ListItem>
          <Divider />

          {order?.lineItems?.map((lineItem) => {
            return (
              <ListItem key={lineItem?.id} sx={receiptStyles}>
                <Box>
                  <Typography variant="p" component="strong" align="left">
                    {lineItem?.name[lang]}
                  </Typography>
                  <br />
                  {getShippingMethod(lineItem) === 'Pick Up' ? (
                    <PickListItem lineItem={lineItem} order={order} />
                  ) : (
                    <>
                      <Typography align="left" display="flex">
                        {lineItem?.name[lang]} x {lineItem?.quantity}
                      </Typography>
                      <Typography display="flex" align="left">
                        {getShippingMethod(lineItem)}
                      </Typography>
                    </>
                  )}
                  <Typography variant="p">SKU: {lineItem?.variant?.sku}</Typography>
                </Box>
                <Typography align="right">{getLineItemPrice(lineItem)}</Typography>
              </ListItem>
            );
          })}
          <Divider sx={{ mt: 2 }} />
          {order?.giftCards?.map((giftCard) => {
            return (
              <ListItem sx={receiptStyles} key={giftCard?.giftCardNumber}>
                <Typography variant="p" align="left">
                  {copyText.Cart.receipt.giftCardXX}
                  {getGiftCardNumber(giftCard)}
                </Typography>
                <Typography align="right">-{getGiftCardAmount(giftCard)}</Typography>
              </ListItem>
            );
          })}
          {promoCodeDiscounts.map((discountCode) => {
            return (
              <ListItem key={discountCode?.id} sx={receiptStyles}>
                <Box>
                  <Typography variant="p" component="strong">
                    {discountCode?.method}
                  </Typography>
                  <Typography>{discountCode?.displayName}</Typography>
                  <Typography>{discountCode?.couponCode}</Typography>
                </Box>
                <Typography>-{discountCode?.centAmount}</Typography>
              </ListItem>
            );
          })}
          <ListItem sx={receiptStyles}>
            <Typography align="left">{copyText.Cart.CartTools.shipping}</Typography>
            <Typography align="right">{shippingTotals}</Typography>
          </ListItem>
          {recyclingFeeTotal && (
            <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
              <Typography>{copyText.Cart.CartTools.recyclingFee}</Typography>
              <Typography align="right">{recyclingFeeTotal}</Typography>
            </ListItem>
          )}
          <ListItem sx={receiptStyles}>
            <Typography align="left">{copyText.Cart.CartTools.tax}</Typography>
            <Typography align="right">{taxTotals}</Typography>
          </ListItem>
          {(order?.custom?.fields?.is_exchange_order || order.is_exchange_order) && (
            <>
              <ListItem sx={receiptStyles}>
                <Typography align="left">{copyText.Cart.receipt.subTotal}</Typography>
                <Typography align="right"> {calculateSubtotal()}</Typography>
              </ListItem>
              <ListItem sx={receiptStyles}>
                <Typography align="left">{copyText.Cart.receipt.exchangeCredit}</Typography>
                <Typography align="right">- {convertCentToDollar(exchangeAmount)}</Typography>
              </ListItem>
            </>
          )}
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="left">
              {copyText.Cart.receipt.totalCaps}
            </Typography>
            <Typography variant="p" component="strong" align="right">
              {totalPrice}
            </Typography>
          </ListItem>

          {refundAmount && (
            <>
              <Divider sx={{ mt: 2 }} />
              <ListItem sx={receiptStyles}>
                <Typography variant="p" component="strong" align="left">
                  {copyText.Cart.receipt.refundCaps}
                </Typography>
                <Typography variant="p" component="strong" align="right">
                  {`(${refundAmount})`}
                </Typography>
              </ListItem>
            </>
          )}
          {!order?.custom?.fields?.is_exchange_order && !order.is_exchange_order && (
            <ExchangeItems order={order} loadOrder={loadOrder} />
          )}
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="right">
              {copyText.Cart.receipt.soldTo}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              marginTop: 2,
              padding: 0,
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Typography variant="p" component="strong" fontSize={20}>
                {`${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`}
              </Typography>
              <Typography fontSize={14}>{order?.shippingAddress?.streetName}</Typography>
              <Typography variant="p" fontSize={14}>
                {`${order?.shippingAddress?.city}, ${order?.shippingAddress?.state} ${order?.shippingAddress?.postalCode}`}
              </Typography>
            </Box>
            <Box
              marginTop={4}
              marginBottom={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography variant="p" component="strong">
                {copyText.App.date}: {date}
              </Typography>
              <Typography variant="p" component="strong">
                {copyText.Cart.receipt.soldBy}: {agentName}
              </Typography>
            </Box>
          </ListItem>
          <Divider />
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              marginTop: 2,
              padding: 0,
            }}
          >
            <Box
              marginTop={2}
              marginBottom={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>{copyText.App.returnWarrantyPolicy}</Typography>
              <Typography>{copyText.App.pleaseVisitUrl}</Typography>
              <Typography>{copyText.App.returnsURL}</Typography>
            </Box>
            <Divider />

            <Typography variant="p" component="strong">
              {copyText.App.customerSupportCenter}
            </Typography>
            <Typography variant="p" component="strong">
              {copyText.App.customerSupportPhone}
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex">
          <Button sx={{ display: 'block', displayPrint: 'none' }} onClick={printThermalReceipt}>
            <TextLoader
              loading={loading}
              text={copyText.Orders.Actions.printThermalReceipt}
              size={20}
            />
          </Button>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            sx={{ display: 'block', displayPrint: 'none', mr: 2 }}
            onClick={closeModal}
          >
            {copyText.App.close}
          </Button>
          <Button
            variant="contained"
            sx={{ display: 'block', displayPrint: 'none' }}
            onClick={() => window.print()}
          >
            {copyText.App.print}
          </Button>
        </Box>
      </DialogActions>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={message}
        action={action}
      />
    </Dialog>
  );
};

ReceiptModal.propTypes = {
  showHeader: bool,
  open: bool.isRequired,
  order: shape({
    customLineItems: arrayOf(shape({})).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
      totalNet: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    orderNumber: string.isRequired,
    createdAt: string.isRequired,
  }).isRequired,
  closeModal: func.isRequired,
  loadOrder: func.isRequired,
};

ReceiptModal.defaultProps = {
  showHeader: true,
};

export default ReceiptModal;
