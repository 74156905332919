import React, { useState, useEffect } from 'react';
import {
  useOrderInput,
  useFoundOrderData,
  useCurrentPage,
  useSelectedStore,
  useSetSelectedStore,
  useSetOrderInput,
  useSetSelectedOrder,
  useSetOrderPayment,
} from 'contexts/orders';
import { useLocation } from 'react-router-dom';
import SearchLayout from 'components/layouts/SearchLayout';
import storeLocation from 'utils/storeLocation';
import { copyText, lang } from 'language';
import OrderFilterModal from './OrderFilterModal';
import UseOrderSearchFunctions from './hooks/OrderSearchFunctions';

const Orders = () => {
  const [orderTableData, setOrderTableData] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search');
  const currentPage = useCurrentPage();
  const foundOrderData = useFoundOrderData();
  const orderInput = useOrderInput();
  const setOrderInput = useSetOrderInput();
  const selectedStore = useSelectedStore();
  const setSelectedStore = useSetSelectedStore();
  const setSelectedOrder = useSetSelectedOrder();
  const setOrderPayment = useSetOrderPayment();

  const {
    useTodaysStoreOrders,
    useSearchUserInput,
    useHandlePageChange,
    useFormatOrderTableData,
    loading,
  } = UseOrderSearchFunctions();

  const handleInput = (event) => {
    setOrderInput(event.target.value);
  };

  const orderColumns = {
    idColumn: copyText.Orders.OrderList.orderNumber,
    columns: [
      copyText.Orders.OrderList.purchaseDate,
      copyText.Orders.OrderList.customerName,
      copyText.Orders.OrderList.customerEmail,
      copyText.Orders.OrderList.location,
    ],
  };

  const openFilterModal = () => {
    setOrderInput('');
    setFilterModal(true);
  };
  const closeFilterModal = () => {
    setFilterModal(false);
  };

  const handleListItemClick = (row) => {
    setOrderPayment(null);
    setSelectedOrder(row.data);
  };
  const performInitialLoad = () => {
    if (searchQuery && foundOrderData.count === 0) {
      setOrderInput(searchQuery);
      useSearchUserInput(searchQuery);
    }
    if (!orderInput && foundOrderData.count === 0) {
      useTodaysStoreOrders();
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    useSearchUserInput();
  };

  const populateTableData = async () => {
    const tableData = await useFormatOrderTableData(foundOrderData.results);
    setOrderTableData(tableData);
  };

  const populateStoreList = async () => {
    const storesList = await storeLocation.getStoreLocationsList();
    setLocationList(storesList);
  };

  useEffect(() => {
    populateTableData();
  }, [foundOrderData.results]);

  useEffect(() => {
    setSelectedStore(storeLocation.getLocationStorage()?.name[lang]);
    performInitialLoad();
  }, []);

  useEffect(() => {
    populateStoreList();
    // cleanup
    return () => {};
  }, [filterModal]);

  return (
    <SearchLayout
      searchLabel={copyText.Orders.Orders.orderSearch}
      searchFunction={handleSearch}
      searchInput={orderInput}
      handleInput={handleInput}
      loading={loading}
      columns={orderColumns.columns}
      idColumn={orderColumns.idColumn}
      rowData={orderTableData}
      numRows={foundOrderData.total}
      handleListItemClick={handleListItemClick}
      handlePageChange={useHandlePageChange}
      currentPage={currentPage - 1} // Table pagination offset
      setFilterToOpen={openFilterModal}
      FilterComponent={OrderFilterModal({
        modalOpenStatus: filterModal,
        selectedStore,
        setSelectedStore,
        closeFilterModal,
        searchFunction: useSearchUserInput,
        locationList,
      })}
    />
  );
};

export default Orders;
