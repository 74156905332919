import {
  Alert,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { copyText } from 'language';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import toDollar from 'utils/toDollar';

const PaymentMethods = ({
  paymentInfo,
  totalPayments,
  setTotalPayments,
  setPaymentsError,
  totalRefundAmount,
  paymentsToRefund,
  setPaymentsToRefund,
}) => {
  const [showError, setShowError] = useState(null);

  const aggregateRefunds = (payment) => {
    // get refunds
    const refundSum = payment.transactions?.reduce((acc, transaction) => {
      if (transaction.state === 'Success' && transaction.type === 'Refund') {
        return acc + transaction.amount.centAmount;
      }
      return acc;
    }, 0);
    return toDollar(refundSum);
  };
  const aggregateCharges = (payment) => {
    // get charges
    const chargeSum = payment.transactions?.reduce((acc, transaction) => {
      if (transaction.state === 'Success' && transaction.type === 'Charge') {
        return acc + transaction.amount.centAmount;
      }
      return acc;
    }, 0);
    return toDollar(chargeSum);
  };

  const checkLimits = (copy) => {
    let withinLimits = true;
    copy.forEach((payment) => {
      const paymentObj = paymentInfo?.payments?.find((item) => item.id === payment.payment_id);
      let maxAmount = 0;
      if (paymentObj) {
        maxAmount = aggregateCharges(paymentObj) - aggregateRefunds(paymentObj);
      }
      if (payment.refund > maxAmount || payment.refund < 0) {
        withinLimits = false;
      }
    });
    return withinLimits;
  };

  const updateTotalPaymentMethods = (copy) => {
    // check all items in paymentsToRefund for valid values
    setShowError(null);
    setPaymentsError(false);
    const withinLimits = checkLimits(copy);
    if (!withinLimits) {
      setShowError(copyText.Orders.Refunds.invalidReturnMethod);
      // disable partial refund buton
      setPaymentsError(true);
    }

    let sum = 0;
    copy.forEach((item) => {
      sum += item.refund;
    });

    setTotalPayments(sum.toFixed(2));
    if (sum === 0 || sum !== Number(totalRefundAmount)) {
      setPaymentsError(true);
      setShowError(copyText.Orders.Refunds.totalAmountError);
    }
  };

  const addToTotalRefund = (item, amount) => {
    const copy = paymentsToRefund;
    const index = copy.findIndex((paymentItem) => paymentItem.payment_id === item.id);
    if (index !== -1) {
      // payment item IN paymentsToRefund -- update
      copy[index].refund = amount;
    } else {
      // payment item not in paymentsToRefund -- add
      copy.push({
        payment_id: item.id,
        refund: amount,
      });
    }
    setPaymentsToRefund(copy);
    return copy;
  };

  const removeFromPaymentsToRefund = (id) => {
    const copy = paymentsToRefund;
    const index = copy.findIndex((payment) => payment.payment_id === id);
    copy.splice(index, 1);
    setPaymentsToRefund(copy);
    return copy;
  };

  const handleInput = async (payment, e) => {
    let copy;
    if (e.target.value === '') {
      copy = removeFromPaymentsToRefund(payment.id);
    } else {
      const amount = Number(e.target.value);
      copy = addToTotalRefund(payment, amount);
    }
    updateTotalPaymentMethods(copy);
  };

  const sumTotalRefundAmount = (payment) => {
    return aggregateCharges(payment) - aggregateRefunds(payment);
  };

  const columns = {
    idColumn: copyText.Orders.Refunds.paymentMethod,
    columns: [
      copyText.Orders.Refunds.paymentAmount,
      copyText.Orders.Refunds.amountRefunded,
      copyText.Orders.Refunds.amountAvailable,
      copyText.Orders.Refunds.amountToRefund,
    ],
  };
  useEffect(() => {
    updateTotalPaymentMethods(paymentsToRefund);
  }, [totalRefundAmount]);

  return (
    <>
      <Table aria-label="simple table" sx={{ mb: 3 }}>
        <TableHead>
          <TableRow sx={{ borderBottom: 3 }}>
            <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              {columns.idColumn}
            </TableCell>
            {columns.columns.map((col) => (
              <TableCell key={col} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentInfo?.payments?.map((row) => (
            <TableRow key={row.id} value={row} sx={{ borderBottom: 2 }}>
              <TableCell sx={{ textAlign: 'center' }}>
                {row.obj?.paymentMethodInfo?.method}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>${aggregateCharges(row.obj)}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>${aggregateRefunds(row.obj)}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>${sumTotalRefundAmount(row.obj)}</TableCell>
              <TableCell sx={{ minWidth: 100, textAlign: 'center' }}>
                {row.obj.paymentMethodInfo?.method === 'GIFT_CARD' && (
                  <Alert severity="warning">{copyText.Orders.Refunds.giftCardRefunds}</Alert>
                )}
                {row.obj.paymentMethodInfo?.method !== 'GIFT_CARD' && (
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="number"
                    disabled={row.obj.paymentMethodInfo?.method === 'GIFT_CARD'}
                    onChange={(e) => handleInput(row, e)}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box sx={{ m: 1 }}>
        <Typography textAlign="right">
          {copyText.Orders.Refunds.totalPayments} ${totalPayments}
        </Typography>
        {showError && (
          <Typography sx={{ mb: 1 }} textAlign="right" color="red" variant="subtitle2">
            {showError}
          </Typography>
        )}
      </Box>
    </>
  );
};

PaymentMethods.propTypes = {
  paymentInfo: shape({
    payments: arrayOf(
      shape({
        id: string.isRequired,
        paymentMethodInfo: shape({
          method: string,
        }),
        transactions: arrayOf(
          shape({
            id: string,
            state: string,
            amount: shape({
              centAmount: number,
            }),
          }),
        ),
      }),
    ),
  }),
  setTotalPayments: func.isRequired,
  totalPayments: string.isRequired,
  setPaymentsError: func.isRequired,
  totalRefundAmount: string.isRequired,
  paymentsToRefund: arrayOf(shape({})).isRequired,
  setPaymentsToRefund: func.isRequired,
};

PaymentMethods.defaultProps = {
  paymentInfo: null,
};

export default PaymentMethods;
