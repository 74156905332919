import { func, shape, string, arrayOf, number } from 'prop-types';
import { Grid, Card, List, ListItem, CardMedia, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import convertCentToDollar from 'utils/convertCentToDollar';

const EditCartCard = ({ variant, handleSelection, selectedVariant, productName }) => {
  const [price, setPrice] = useState('0.00');
  const [variantAttributes, setVariantAttributes] = useState([]);
  const formatAttributes = (attributes) => {
    const resp = {};
    attributes.forEach((attr) => {
      resp[attr.name] = attr.value;
    });
    setVariantAttributes(resp);
  };

  useEffect(() => {
    formatAttributes(variant.attributes);
    setPrice(convertCentToDollar(variant.prices[0]?.value?.centAmount));
  }, []);
  return (
    <Card
      // variant={variant.sku === selectedVariant.sku ? 'outlined' : ''}
      color="primary"
      raised={variant.sku === selectedVariant.sku ? 1 : 0}
      onClick={() => handleSelection(variant)}
    >
      <Grid container columns={16} display="flex" flexDirection="row">
        <Grid p={1} item xs={16} sm={4}>
          <CardMedia component="img" src={variant?.images[0]?.url} alt={productName} />
        </Grid>
        <Grid item xs={16} sm={10} display="flex" alignItems="left" flexDirection="column">
          <List disablePadding>
            <ListItem
              disableGutters
              sx={{ display: 'flex', mt: 0, p: 0, justifyContent: 'space-between' }}
            >
              <Typography align="left" component="p" variant="h6">
                {variantAttributes.size?.label}
                {variantAttributes['pillow-height']?.label}
              </Typography>
              <Typography align="right" component="p" variant="h6" color="secondary">
                {price}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <Typography component="p" variant="h3">
                {variantAttributes.color?.label}
                {variantAttributes['pillow-size']?.label}
                {variantAttributes['cover-type']?.label}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <Typography variant="p">{variantAttributes.gender?.label}</Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Card>
  );
};

EditCartCard.propTypes = {
  productName: string.isRequired,
  selectedVariant: shape({
    sku: string.isRequired,
  }).isRequired,
  handleSelection: func.isRequired,
  variant: shape({
    prices: arrayOf(
      shape({
        value: shape({
          centAmount: number.isRequired,
        }).isRequired,
      }).isRequired,
    ),
    sku: string.isRequired,
    images: arrayOf(
      shape({
        url: string.isRequired,
      }).isRequired,
    ).isRequired,
    attributes: arrayOf(
      shape({
        value: shape({
          label: string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default EditCartCard;
