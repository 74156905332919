import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { copyText, lang } from 'language';
import stores from 'dataAccess/api/stores.ts';
import { CloseOutlined } from '@mui/icons-material';
import shippingMethod from 'utils/shippingMethods';
import { useAddToCartLoading, useCart, useSetAddToCartLoading, useSetCart } from 'contexts';
import { addToCart } from 'utils/cart';
import TextLoader from 'components/TextLoader';
import { shape, string } from 'prop-types';
import InventoryUpdateBtn from 'components/InventoryUpdateBtn/InventoryUpdateBtn';

const InventoryDialog = ({ product }) => {
  const cart = useCart();
  const setCart = useSetCart();
  const loading = useAddToCartLoading();
  const setLoading = useSetAddToCartLoading();
  const [show, setShow] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [quantity, setQuantity] = useState(0);

  const getAvailableQuantity = (item) => {
    if (item.availableQuantity) {
      return `${copyText.App.available}: ${item.availableQuantity}`;
    }
    return copyText.Cart.CartTools.outOfStock;
  };

  const populateQuantity = () => {
    if (cart && cart.lineItems && product && product.sku) {
      const prod = cart?.lineItems.find((lineItem) => lineItem.variant.sku === product.sku);
      if (prod) {
        setQuantity(prod.quantity);
      }
    }
  };

  const updateShipping = async (updatedCart) => {
    try {
      const params = { cart: updatedCart, sku: product.sku, storeKey: selectedStore.key };
      const result = await shippingMethod.addPickupToLineItem(params);
      if (result === null) {
        setCart(updatedCart);
      } else {
        setCart(result);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddToCart = async () => {
    try {
      setLoading(true);
      const updatedCart = await addToCart(product, cart);
      await updateShipping(updatedCart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const mapChannelToStore = async () => {
    const storeData = await stores.getStores();
    if (product && product.channels) {
      let storeInventory = storeData.map((store) => {
        // TODO: Account for multiple supply channels
        const channel = product.channels[store.supplyChannels[0].id];
        return { ...store, ...channel };
      });
      storeInventory = storeInventory.sort(
        (a, b) => (b.availableQuantity ?? 0) - (a.availableQuantity ?? 0),
      );
      setInventory(storeInventory);
    }
  };

  useEffect(() => {
    populateQuantity();
  }, [cart]);

  useEffect(() => {
    mapChannelToStore();
  }, [product]);

  return (
    <>
      <Button onClick={() => setShow(true)}>{copyText.Cart.CartTools.viewPickupOptions}</Button>
      <Dialog open={show} fullWidth>
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightGrayBG' }}
        >
          <Box>
            <Typography variant="h5" component="h5">
              {copyText.Cart.CartTools.pickupAvailability}
            </Typography>
            <Typography variant="subtitle2">{copyText.Cart.CartTools.selectLocation}</Typography>
          </Box>
          <IconButton onClick={() => setShow(false)} aria-label="show-pickup-availability">
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Alert severity="warning" sx={{ mb: 2 }}>
            {copyText.Cart.CartTools.alwaysCallAhead}
          </Alert>
          <InventoryUpdateBtn product={product} />
          <Box sx={{ maxHeight: '300px', overflow: 'scroll' }}>
            {inventory?.map((item) => {
              return (
                <MenuItem
                  selected={item.key === selectedStore.key}
                  key={item.id}
                  value={item.key}
                  onClick={() => setSelectedStore(item)}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Box>
                    <Typography fontWeight="bold">{item.name[lang]}</Typography>
                    {item.key === selectedStore.key && (
                      <Typography variant="subtitle2">
                        {item.phone ?? copyText.Cart.CartTools.phoneNumberMissing}
                      </Typography>
                    )}
                    <Typography variant="subtitle2">{item.key}</Typography>
                  </Box>
                  <Typography variant="subtitle2">{getAvailableQuantity(item)}</Typography>
                </MenuItem>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3, backgroundColor: 'lightGrayBG' }}>
          <Button onClick={() => setShow(false)}>{copyText.App.cancel}</Button>
          <Badge
            data-testid="add-to-cart-badge"
            color="secondary"
            overlap="rectangular"
            badgeContent={quantity}
            component="p"
          >
            <Button disabled={!selectedStore} onClick={handleAddToCart}>
              <TextLoader
                loading={loading}
                size={20}
                text={copyText.Cart.CartTools.addPickupToCart}
              />
            </Button>
          </Badge>
        </DialogActions>
      </Dialog>
    </>
  );
};

InventoryDialog.propTypes = {
  product: shape({
    sku: string.isRequired,
  }).isRequired,
};

export default InventoryDialog;
