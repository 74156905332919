import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Link,
  Icon,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import copyText from 'language/enUS';
import React, { useEffect, useState } from 'react';
import { lang } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import toDollar from 'utils/toDollar';
import { ArrowBack, Warning } from '@mui/icons-material';
import refunds from 'dataAccess/api/refunds/refunds.ts';
import TextLoader from 'components/TextLoader';
import { useParams, Link as RouterLink } from 'react-router-dom';
import orders from 'dataAccess/api/orders.ts';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';
import RefundModal from './components/RefundModal/RefundModal';

const Refund = () => {
  const urlParams = useParams();

  const [currentOrder, setCurrentOrder] = useState({});
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [lineItemDetails, setLineItemDetails] = useState([]);
  const [totalRefundAmount, setTotalRefundAmount] = useState('0.00');
  const [totalPayments, setTotalPayments] = useState('');
  const [showError, setShowError] = useState(null);
  const [paymentsError, setPaymentsError] = useState(false);
  const [paymentsToRefund, setPaymentsToRefund] = useState([]);
  const [refundStatus, setRefundStatus] = useState({});
  const [showModal, setShowModal] = useState(false);

  const getByOrderNumber = async () => {
    const orderNumber = urlParams.orderId;
    const result = await orders.getByOrderNumber(orderNumber);
    setCurrentOrder(result.order);
  };

  const removeFromLineItemDetails = (id) => {
    const copy = lineItemDetails;
    const index = copy.findIndex((lineItem) => lineItem.lineItemId === id);
    copy.splice(index, 1);
    setLineItemDetails(copy);
    return copy;
  };

  const getPrevRefunds = () => {
    const prevRefunds = [];
    currentOrder?.paymentInfo?.payments?.forEach((payment) => {
      const successfulInteractions = payment.obj?.interfaceInteractions?.filter(
        (interaction) =>
          interaction.fields?.interaction_status === 'Completed' &&
          interaction.fields?.refunded_line_items?.length > 0,
      );
      const lineItemRefunds = successfulInteractions.map(
        (interaction) => interaction.fields?.refunded_line_items,
      );
      prevRefunds.push(lineItemRefunds);
    });
    return prevRefunds;
  };

  const getRefundedAmount = (lineItem) => {
    if (!currentOrder) {
      return '';
    }

    const prevRefunds = getPrevRefunds();
    let totalRefund = 0;

    prevRefunds.forEach((prevRefund) => {
      prevRefund.forEach((refund) => {
        const parsedRefund = JSON.parse(refund);

        if (Array.isArray(parsedRefund)) {
          parsedRefund.forEach((item) => {
            if (item.line_item_id === lineItem.id) {
              totalRefund += item.amount || 0;
            }
          });
        } else if (parsedRefund.line_item_id === lineItem.id) {
          totalRefund += parsedRefund.amount || 0;
        }
      });
    });

    return totalRefund.toFixed(2);
  };

  const checkLimits = (refundItems) => {
    let withinLimits = true;
    refundItems.forEach((item) => {
      const matchedLineItem = currentOrder.lineItems.find(
        (lineItem) => lineItem.id === item.lineItemId,
      );
      const lineItemTotalPrice = matchedLineItem.taxedPrice.totalGross.centAmount;
      let dollarAmount = toDollar(lineItemTotalPrice);
      dollarAmount -= getRefundedAmount(matchedLineItem);
      if (item.amount < 0 || item.amount > dollarAmount) {
        withinLimits = false;
      }
    });
    return withinLimits;
  };

  const updateTotalRefund = (copy) => {
    setShowError(null);
    const withinLimits = checkLimits(copy);
    if (!withinLimits) {
      setShowError(copyText.Orders.Refunds.amountsError);
    }
    let sum = 0;

    copy.forEach((item) => {
      sum += item.amount;
    });
    setTotalRefundAmount(sum.toFixed(2));
  };

  const disableButton = () => {
    return lineItemDetails.length === 0 || showError || loading || paymentsError || !reason;
  };

  const addToTotalRefund = (item, amount) => {
    const copy = lineItemDetails;
    const index = copy.findIndex((refundItem) => refundItem.lineItemId === item.id);
    if (index !== -1) {
      copy[index].amount = amount;
    } else {
      copy.push({
        lineItemId: item.id,
        sku: item.variant.sku,
        amount,
        itemQuantity: item.quantity,
      });
    }
    setLineItemDetails(copy);
    return copy;
  };

  const getAvailableRefundAmount = (lineItem) => {
    return lineItem.taxedPrice.totalGross.centAmount / 100 - getRefundedAmount(lineItem);
  };

  const handleInput = async (item, e) => {
    let lineItemRefunds;
    if (e.target.value === '') {
      lineItemRefunds = removeFromLineItemDetails(item.id);
    } else {
      const amount = Number(e.target.value);
      lineItemRefunds = addToTotalRefund(item, amount);
    }
    updateTotalRefund(lineItemRefunds);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setShowModal(true);
      const params = {
        orderId: currentOrder.id,
        manualRefunds: paymentsToRefund,
        lineItemDetails,
        reason,
      };
      const result = await refunds.manualRefund(params);
      setRefundStatus(result);
      setLoading(false);
    } catch (error) {
      setRefundStatus(error);
      setLoading(false);
    }
  };

  const orderColumns = {
    idColumn: copyText.Orders.Refunds.productName,
    columns: [
      copyText.Orders.Refunds.sku,
      copyText.Orders.Refunds.quantity,
      copyText.Orders.Refunds.amountPaid,
      copyText.Orders.Refunds.amountRefunded,
      copyText.Orders.Refunds.amountAvailable,
      copyText.Orders.Refunds.amountToRefund,
    ],
  };

  useEffect(() => {
    getByOrderNumber();
  }, []);

  return (
    <Container disableGutters sx={{ mb: 15, p: 3 }}>
      <Grid container justifyContent="flex-start" alignItems="center" sx={{ p: 1.5, mt: 10 }}>
        <Grid item xs={12}>
          <Link
            component={RouterLink}
            to={{
              pathname: `/order/${urlParams.orderId}`,
            }}
            sx={{ display: 'flex', textAlign: 'center', textDecoration: 'none', mb: 1 }}
          >
            <Icon sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
              <ArrowBack />
            </Icon>
            <Typography variant="h7">{copyText.Orders.Refunds.returnToOrder}</Typography>
          </Link>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography component="h4" variant="h4">
              {copyText.Orders.Refunds.partialRefund}
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography align="right" component="h5" variant="h6" color="secondary">
                {copyText.App.orderNumber}: {currentOrder?.orderNumber}
              </Typography>
              <Typography align="right" variant="h6" color="secondary">
                {copyText.App.email}: {currentOrder?.customerEmail}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Alert icon={<Warning />} color="warning">
            {copyText.Orders.Refunds.warning}
          </Alert>
          <TableContainer
            component={Paper}
            sx={{ height: { xs: '72vh', sm: '50vh', md: '42vh' }, pl: 1, pr: 1 }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ borderBottom: 3 }}>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {orderColumns.idColumn}
                  </TableCell>
                  {orderColumns.columns.map((col) => (
                    <TableCell key={col} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentOrder?.lineItems?.length < 1 && (
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ fontWeight: 'bold' }}>
                      {copyText.Layouts.TableLayout.noDataFound}
                    </TableCell>
                  </TableRow>
                )}
                {currentOrder?.lineItems?.map((row) => (
                  <TableRow key={row.id} value={row} sx={{ borderBottom: 2 }}>
                    <TableCell sx={{ textAlign: 'center' }}>{row.name[lang]}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.variant.sku}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.quantity}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {convertCentToDollar(row.taxedPrice.totalGross.centAmount)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>${getRefundedAmount(row)}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      ${getAvailableRefundAmount(row)}
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, alignContent: 'center' }}>
                      {/* amount to refund */}
                      <TextField
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          min: 0,
                          max: row.taxedPrice.totalGross.centAmount,
                        }}
                        //   defaultValue={0}
                        type="number"
                        onChange={(e) => handleInput(row, e)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ m: 1 }}>
            <Typography textAlign="right">
              {copyText.Orders.Refunds.totalAmountToRefund} $ {totalRefundAmount}
            </Typography>
            {showError && (
              <Typography sx={{ mb: 1 }} textAlign="right" color="red" variant="subtitle2">
                {showError}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={12}>
          <PaymentMethods
            paymentInfo={currentOrder.paymentInfo}
            totalPayments={totalPayments}
            setTotalPayments={setTotalPayments}
            setPaymentsError={setPaymentsError}
            totalRefundAmount={totalRefundAmount}
            paymentsToRefund={paymentsToRefund}
            setPaymentsToRefund={setPaymentsToRefund}
          />
        </Grid>
        <Grid xs={12} item display="flex" flexDirection="column" justifyContent="space-between">
          <TextField
            fullWidth
            multiline
            onChange={(e) => setReason(e.target.value)}
            minRows={4}
            sx={{ mb: 3 }}
            placeholder={`${copyText.Orders.Refunds.refundReason} (${copyText.Orders.Refunds.required})`}
          />
          <Button disabled={disableButton()} onClick={handleSubmit} variant="contained">
            <TextLoader
              text={copyText.Orders.Refunds.createPartialRefund}
              loading={loading}
              size={20}
            />
          </Button>
        </Grid>
      </Grid>
      <RefundModal
        loading={loading}
        show={showModal}
        setShow={setShowModal}
        refundStatus={refundStatus}
        handleRetry={handleSubmit}
      />
    </Container>
  );
};

export default Refund;
