const isValidEmail = (emailCandidate) => {
  // check if the input is an email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(emailCandidate);
};

const isValidPhoneNumber = (phoneNumber) => /^[0-9+()\-\s]+$/.test(phoneNumber);

export default {
  isValidEmail,
  isValidPhoneNumber,
};
