import React from 'react';
import { Typography, Grid, List } from '@mui/material';
import { copyText } from 'language';
import ShippingInfoInput from './AddressInput/ShippingInfoInput';
import CartMenu from './CartMenu/CartMenu';
// import EmailInput from './EmailInput/EmailInput';

const CustomerInfo = () => {
  return (
    <Grid
      sx={{ backgroundColor: 'lightGrayBG' }}
      marginBottom={4}
      container
      display="flex"
      flexDirection="column"
    >
      <Grid item display="flex" paddingTop={3} alignItems="center" justifyContent="space-between">
        <Typography paddingLeft={2} alignItems="center" variant="h5" component="h2">
          {copyText.Cart.CartTools.customerInfo}
        </Typography>
        <CartMenu />
      </Grid>
      <List>
        <ShippingInfoInput />
      </List>
    </Grid>
  );
};

export default CustomerInfo;
