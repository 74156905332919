import React from 'react';
import { Container, Grid } from '@mui/material';
import LeftColumn from './Components/LeftColumn/LeftColumn';
import RightColumn from './Components/RightColumn/RightColumn';

const Cart = () => {
  return (
    <Container sx={{ mt: 10, mb: 15 }}>
      <Grid container columnSpacing={4} columns={16}>
        <LeftColumn />
        <RightColumn />
      </Grid>
    </Container>
  );
};

export default Cart;
