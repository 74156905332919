const getDiscountCodes = (cart) => {
  const discountCodesArray = new Set(); // Use a Set to automatically handle duplicates

  cart?.lineItems?.forEach((lineItem) => {
    const discounts = lineItem.custom?.fields?.discounts_json
      ? JSON.parse(lineItem.custom?.fields?.discounts_json)
      : [];
    // only keep discount code from discounts
    discounts.forEach((item) => {
      discountCodesArray.add(item.display_name);
    });
  });

  return [...discountCodesArray]; // Convert Set back to an array
};

export default getDiscountCodes;
