import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useCart } from 'contexts';
import { copyText } from 'language';
import clover from 'dataAccess/api/clover.ts';
import financialCalculators from 'utils/financialCalculators/financialCalculators';

const currency = '$';
const CheckoutButtonNEW = () => {
  const [customPayment, setCustomPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [paymentAmount, setPaymentAmount] = useState(null);
  // SUNSET: REMOVE ESLINT-DISABLE WHEN READY TO SET UP PAYMENTS
  const [payments, setPayments] = useState([]); // eslint-disable-line
  const [remainingTotal, setRemainingTotal] = useState(0);
  // SUNSET: REMOVE ESLINT-DISABLE WHEN READY TO SET UP PAYMENTS
  const [error, setError] = useState(null); // eslint-disable-line
  const [customAmountError, setCustomAmountError] = useState(null);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const cart = useCart();

  const checkCustomAmount = (amount) => {
    if (amount > remainingTotal || amount <= 0) {
      setCustomAmountError('Invalid amount');
      return false;
    }
    return true;
  };

  const checkDisableCheckout = (amount) => {
    if (amount && checkCustomAmount(amount) === false) {
      setCheckoutDisabled(true);
    }
    setCheckoutDisabled(false);
  };

  const calculateRemainingTotal = () => {
    let cartTotal = financialCalculators.getTotalPrice(cart);

    payments.forEach((payment) => {
      cartTotal -= payment.amount ?? 0;
    });
    setRemainingTotal(cartTotal / 100);
  };

  const handleProcessPayment = async () => {
    try {
      const payment = parseFloat(paymentAmount) * 100;
      // SUNSET: Remove eslint disable when ready to set up payments.
      // eslint-disable-next-line no-unused-vars
      const result = await clover.paymentRequest({
        cartId: cart.id,
        paymentTotal: payment,
        manualEntry: true,
      });
      // SUNSET: ADD BACK IN WHEN READY TO SET UP PAYMENTS
      // setPayments([...payments, result.data])
    } catch (err) {
      setError(err);
    }
  };

  const handleCancel = async () => {
    setShow(false);
    setCustomPayment(false);
    setShowCancelWarning(false);
  };

  const handleCustomAmountInput = (amount) => {
    // Remove non-numeric characters and leading zeros
    const cleanedInput = amount.replace(/[^\d]/g, '').replace(/^0+/, '');

    // Ensure at least one digit is entered
    if (cleanedInput.length === 0) {
      const zero = parseFloat(0) / 100;
      setPaymentAmount(zero.toFixed(2));
      return;
    }

    // Convert to currency format
    const currencyAmount = parseFloat(cleanedInput) / 100;
    setPaymentAmount(currencyAmount.toFixed(2));
    checkDisableCheckout(currencyAmount);
  };

  const handlePayInFull = () => {
    setPaymentAmount(calculateRemainingTotal());
    setCustomPayment(false);
  };

  useEffect(() => {
    calculateRemainingTotal();
  }, [payments, cart, show]);

  return (
    <>
      <Button onClick={() => setShow(true)}>{copyText.Cart.CheckoutButtons.proceed}</Button>
      <Dialog fullWidth open={show}>
        <DialogTitle
          sx={{ display: 'flex', p: 3, justifyContent: 'space-between' }}
          alignItems="center"
        >
          <Typography>{copyText.Cart.CheckoutButtons.paymentMethod}</Typography>
          <TextField label="Remaining Total" disabled value={remainingTotal} />
        </DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            sx={{ mb: 3 }}
            value={paymentMethod}
            label={copyText.Cart.CheckoutButtons.proceed}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <MenuItem value="card">{copyText.Cart.CheckoutButtons.card}</MenuItem>
            <MenuItem disabled value="cash">
              {copyText.Cart.CheckoutButtons.cash}
            </MenuItem>
          </Select>
          <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={<Checkbox checked={customPayment === false} onChange={handlePayInFull} />}
                label={copyText.Cart.CheckoutButtons.payInFull}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography>{copyText.Cart.CheckoutButtons.customPayment}</Typography>
              <Checkbox checked={customPayment === true} onChange={() => setCustomPayment(true)} />
              <TextField
                InputProps={{
                  startAdornment: <Typography>{currency}</Typography>,
                }}
                error={customAmountError}
                type="number"
                placeholder={copyText.Cart.CheckoutButtons.amount}
                onChange={(e) => handleCustomAmountInput(e.target.value)}
                value={paymentAmount}
                disabled={!customPayment}
              />
            </Box>
          </Box>
          {showCancelWarning && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              <Typography textAlign="center">
                {copyText.Cart.CheckoutButtons.cancelWarning}
              </Typography>
              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button variant="outlined" onClick={handleCancel}>
                  {copyText.Cart.CheckoutButtons.yesCancel}
                </Button>
                <Button variant="contained" onClick={() => setShowCancelWarning(false)}>
                  {copyText.Cart.CheckoutButtons.noContinue}
                </Button>
              </Box>
            </Alert>
          )}
          {payments.length > 0 && (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>{copyText.Cart.CheckoutButtons.authorizedPayments}</Typography>
              </Box>
              <Divider />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button sx={{ mr: 3 }} onClick={() => setShowCancelWarning(true)} variant="outlined">
            {copyText.App.cancel}
          </Button>
          <Button disabled={checkoutDisabled} onClick={handleProcessPayment} variant="contained">
            {copyText.Cart.CheckoutButtons.processPayment}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CheckoutButtonNEW;
