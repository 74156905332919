// SUNRISE: Uncomment after logging lambda is ready
// import errorApi from 'dataAccess/api/errorHandling.ts';

const checkIsJsonString = (data) => {
  try {
    JSON.parse(data);
  } catch (error) {
    return false;
  }
  return true;
};

const getErrorsFromStorage = () => {
  const data = localStorage.getItem('errorData');
  if (!data) {
    return [];
  }
  if (data && checkIsJsonString(data)) {
    return JSON.parse(data);
  }
  return ['ERRORS NOT PARSABLE'];
};

const logErrorToLocalStorage = ({ message, source, method }, errorInfo = {}) => {
  // Maximum entries allowed for errorData storage
  const maxEntries = 200;
  const errorStorage = getErrorsFromStorage();

  if (errorStorage.length > maxEntries) {
    errorStorage.pop();
  }

  const newMsg = message || '';
  const newSrc = source || '';
  const newMethod = method || '';
  const newErrorInfo = errorInfo ? JSON.stringify(errorInfo) : '';
  const timestamp = new Date().toISOString();

  const newEntry = `| ${timestamp} | ${newMethod} | ${newSrc} | ${newMsg} | ${newErrorInfo} |`;
  const errorData = [newEntry, ...errorStorage];

  localStorage.setItem('errorData', JSON.stringify(errorData));
};

// SUNSET: Deprecate 'src' in favor of 'method'
const logError = async ({ message, source, errorInfo, src = null, method }) => {
  // SUNSET: Remove next line after logging lambda is ready
  logErrorToLocalStorage({ message, source, method: src || method }, errorInfo);

  // SUNRISE: Uncomment after logging lambda is ready
  // try {
  //   await errorApi.logErrorToAPI(errorInfo);
  //   return true;
  // } catch (err) {
  //   logErrorToLocalStorage({ message, source, method: src || method }, errorInfo);
  //   return false;
  // }
};

export { checkIsJsonString, logErrorToLocalStorage, getErrorsFromStorage, logError };
