import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { string, func, node, arrayOf } from 'prop-types';

const DetailLayout = (props) => {
  const {
    identifier,
    lastPageFunction,
    lastPageText,
    DetailComponents,
    ActionsComponent,
    BreakdownComponent,
    TotalsComponents,
  } = props;

  return (
    <Container disableGutters>
      <Grid container sx={{ pl: 2, pr: 2, mt: 9 }} alignItems="end">
        <Grid item sm={4} display="flex" alignItems="center">
          <ArrowBackIcon fontSize="small" />
          <Button onClick={lastPageFunction} color="primary">
            {lastPageText}
          </Button>
        </Grid>
        <Grid item sm={8}>
          <Typography
            component="h1"
            ml={{ sm: 5, md: 10 }}
            sx={{ fontWeight: 'bold', fontSize: 35 }}
          >
            {identifier}
          </Typography>
        </Grid>
        <Grid item md={12} sm={16}>
          {ActionsComponent}
        </Grid>
      </Grid>
      <Grid container columns={16} sx={{ pl: 2, pr: 2 }}>
        <Grid item sm={16} md={4}>
          <Grid container direction={{ sm: 'row', md: 'column' }}>
            {DetailComponents.map((component) => {
              return (
                <Grid item key={component.key} sm={6} md={4}>
                  {component}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item md={8} sm={10}>
          {BreakdownComponent}
        </Grid>
        <Grid item md={4} sm={6}>
          {TotalsComponents.map((component) => {
            return component;
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DetailLayout;

DetailLayout.propTypes = {
  identifier: string.isRequired,
  lastPageFunction: func.isRequired,
  lastPageText: string.isRequired,
  DetailComponents: arrayOf(node).isRequired,
  ActionsComponent: node.isRequired,
  BreakdownComponent: node.isRequired,
  TotalsComponents: arrayOf(node).isRequired,
};
