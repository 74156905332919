import { Button, Typography } from '@mui/material';
import TextLoader from 'components/TextLoader';
import { useSetCart } from 'contexts';
import { bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import { addCartToHistory } from 'utils/cartHistory';
import createCart from 'utils/createCart';

const ClearCartBtn = ({ disabled, text, onComplete, preEvent }) => {
  const [loading, setLoading] = useState(false);
  const setCart = useSetCart();
  const clearCart = async () => {
    try {
      if (preEvent) {
        await preEvent();
      }

      setLoading(true);
      const newCart = await createCart();
      setCart(newCart);
      addCartToHistory(newCart);

      if (onComplete) {
        await onComplete(newCart);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Button sx={{ m: 1 }} disabled={loading || disabled} variant="contained" onClick={clearCart}>
      <Typography sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }} color="white" variant="h3" component="span">
        <TextLoader text={text ?? ''} loading={loading} size={14} />
      </Typography>
    </Button>
  );
};

ClearCartBtn.propTypes = {
  text: string.isRequired,
  disabled: bool,
  preEvent: func,
  onComplete: func,
};

ClearCartBtn.defaultProps = {
  preEvent: null,
  disabled: false,
  onComplete: null,
};

export default ClearCartBtn;
