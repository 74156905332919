import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';
import { Check, Close } from '@mui/icons-material';
import { func, bool } from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

import { copyText } from 'language';
import cartService from 'dataAccess/api/cart.ts';
import { useCart } from 'contexts';
import draftOrders from 'utils/draftOrders';
import emailValidation from 'utils/formValidation';
import TextLoader from 'components/TextLoader';
import ProcessIndicator from 'components/ProcessIndicator/ProcessIndicator';

const SendDraftModal = ({ handleSetOpen, open }) => {
  // For getting agent details from Okta
  const { oktaAuth } = useOktaAuth();

  const cart = useCart();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('');
  const [statusIcon, setStatusIcon] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const maxNoteLength = 261;

  const handleClose = () => {
    handleSetOpen(false);
    setEmail(cart?.customerEmail || '');
  };

  const markCartAsDraft = async () => {
    try {
      setStatus(copyText.Cart.CartTools.DraftOrder.markingCartAsDraft);
      setLoading(true);
      setStatusIcon(<CircularProgress size={20} />);

      const result = await cartService.markCartAsDraft(cart?.id);
      return result;
    } catch (error) {
      setStatusIcon(<Close />);
      setStatus(copyText.Cart.CartTools.DraftOrder.markAsDraftFailure);
      return false;
    }
  };

  const sendDraftOrderEmail = async () => {
    try {
      setStatus(copyText.Cart.CartTools.DraftOrder.sendDraft);
      const params = await draftOrders.formatDraftOrderItems(
        email,
        cart,
        oktaAuth,
        note,
        customerName,
      );
      const draftEmailSent = await cartService.sendDraftOrderEmail(params);
      if (draftEmailSent) {
        setStatusIcon(<Check />);
        setStatus(copyText.Cart.CartTools.DraftOrder.sendDraftSuccess);
        handleSetOpen(false);
        handleClose();
        setOpenSnackbar(true);
      }
    } catch (error) {
      setStatus(copyText.Cart.CartTools.DraftOrder.sendDraftFailure);
      setLoading(false);
      setStatusIcon(<Close />);
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    if (emailValidation.isValidEmail(inputEmail)) {
      setEmail(inputEmail);
    } else {
      setEmail('');
    }
  };

  const handleAgentNoteChange = (agentNote) => {
    let htmlNote = agentNote;
    htmlNote = htmlNote.replace(/\n/g, '<br>');
    setNote(htmlNote);
  };

  const handleSend = async () => {
    try {
      await markCartAsDraft();
      await sendDraftOrderEmail();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setStatusIcon(<Close />);
    }
  };

  useEffect(() => {
    setEmail(cart?.customerEmail);
    setCustomerName(cart?.shippingAddress?.firstName);
  }, [cart?.customerEmail, cart?.shippingAddress]);

  return (
    <>
      <Dialog data-testid="sendDraft-dialog-box" open={open} onClose={handleClose}>
        <Box>
          <DialogTitle
            sx={{ backgroundColor: 'lightGrayBG' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{copyText.Cart.CartTools.DraftOrder.sendDraftTitle}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <TextField
              label={copyText.Cart.CartTools.email}
              sx={{ mt: 2, mb: 3 }}
              defaultValue={email}
              fullWidth
              placeholder={copyText.Cart.CartTools.customerEmail}
              onChange={handleEmailChange}
            />
            <TextField
              sx={{ mb: 3 }}
              defaultValue={customerName}
              label={copyText.Cart.CartTools.DraftOrder.customerFirstName}
              fullWidth
              onChange={(e) => setCustomerName(e.target.value)}
            />
            <TextField
              rows={5}
              multiline
              label={`${copyText.Cart.CartTools.DraftOrder.addNote} (${note.length}/${maxNoteLength} ${copyText.Cart.CartTools.DraftOrder.charLimit}) `}
              placeholder={copyText.Cart.CartTools.DraftOrder.addNotePlaceholder}
              // SUNRISE: Fix value to be editable
              // value={copyText.Cart.CartTools.DraftOrder.addNotePlaceholder}
              fullWidth
              inputProps={{ maxLength: 261 }}
              onChange={(e) => handleAgentNoteChange(e.target.value)}
            />
            <Alert icon={false} sx={{ p: 3, mt: 3, ml: 1, mr: 1 }}>
              <Typography sx={{ mb: 1 }}>
                {copyText.Cart.CartTools.DraftOrder.advisoryOne}{' '}
              </Typography>
              <Typography>
                <strong>{copyText.Cart.CartTools.DraftOrder.advisoryTwo}</strong>
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: 'lightGrayBG',
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid columns={16} container display="flex" justifyContent="space-between">
              <Grid item xs={0} sx={{ display: { xs: 'none', sm: 'flex' } }} sm={10}>
                <ProcessIndicator statusIcon={statusIcon} text={status} />
              </Grid>
              <Grid item xs={16} sm={6}>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    sx={{ mr: 3, pl: 3, pr: 3 }}
                    data-testid="sendDraft-cancel"
                    onClick={handleClose}
                  >
                    {copyText.App.cancel}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!email || loading}
                    data-testid="sendDraft-send"
                    onClick={handleSend}
                    sx={{ pl: 3, pr: 3, mr: 2 }}
                  >
                    <TextLoader text={copyText.App.send} size={20} loading={loading} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={copyText.Cart.CartTools.DraftOrder.successFullySent}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <Close />
          </IconButton>
        }
      />
    </>
  );
};

SendDraftModal.propTypes = {
  handleSetOpen: func.isRequired,
  open: bool.isRequired,
};

export default SendDraftModal;
