import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Snackbar,
  IconButton,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { copyText, lang } from 'language';
import { string } from 'prop-types';
import location from 'utils/storeLocation';
import feedbackService from 'dataAccess/api/feedback.ts';
import TextLoader from 'components/TextLoader';
import { getErrorsFromStorage } from 'utils/errorUtils';

const Feedback = ({ username }) => {
  const [storeData, setStoreData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(copyText.Feedback.bug);
  const [feedbackResponse, setFeedbackResponse] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [comments, setComments] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const feedbackTypes = [
    copyText.Feedback.bug,
    copyText.Feedback.featureRequest,
    copyText.Feedback.other,
  ];
  const sendFeedbackRequest = async () => {
    setLoading(true);
    try {
      const errorLogs = getErrorsFromStorage();
      const request = {
        storeName: storeData.name[lang],
        selectedFeedbackType,
        title,
        comments,
        agent: username,
        webPage: window.location.href,
        errorLogs,
      };

      const result = await feedbackService.sendFeedBack(request);
      setShowSnackbar(true);
      if (result.status === 200) {
        setFeedbackResponse(`${copyText.Feedback.successfullySubmitted}: ${result.data.key}`);
        setLoading(false);
        setShowModal(false);
      } else {
        throw new Error(copyText.Feedback.tryAgain);
      }
    } catch (error) {
      setFeedbackResponse(`${copyText.Feedback.failedToSendFeedback}. ${error.message}`);
      setShowSnackbar(true);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const handleOpenModal = () => {
    setComments('');
    setTitle('');
    setShowModal(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const selectFeedbackType = (e) => {
    setSelectedFeedbackType(e.target.value);
  };

  const saveComments = (e) => {
    setComments(e.target.value);
    return comments;
  };

  const saveTitle = (e) => {
    setTitle(e.target.value);
    return title;
  };

  const populateStoreData = () => {
    const store = location.getLocationStorage();
    setStoreData(store);
  };

  useEffect(() => {
    populateStoreData();
  }, [showModal]);

  return (
    <>
      <Button
        sx={{ marginLeft: 3, height: '2.5rem' }}
        variant="contained"
        onClick={handleOpenModal}
      >
        {copyText.Feedback.sendFeedback}
      </Button>
      <Dialog open={showModal} fullWidth>
        <DialogTitle sx={{ p: 3, backgroundColor: 'lightGrayBG' }}>
          {copyText.Feedback.sendFeedback}
        </DialogTitle>
        <DialogContent>
          <Grid container columns={16} display="flex" flexDirection="column">
            <Grid item marginTop={3}>
              <TextField
                sx={{ marginBottom: 2 }}
                label={copyText.App.pageURL}
                value={window.location.href}
                fullWidth
                disabled
              />
            </Grid>
            <TextField
              sx={{ marginBottom: 2 }}
              label={copyText.App.store}
              placeholder={copyText.App.store}
              value={storeData?.name[lang]}
              disabled
              InputProps={{
                endAdornment: !storeData && (
                  <Button
                    onClick={populateStoreData}
                    sx={{ pl: 3, pr: 3 }}
                    variant="contained"
                    color="error"
                  >
                    {copyText.App.refresh}
                  </Button>
                ),
              }}
            />
            <TextField
              sx={{ marginBottom: 2 }}
              label={copyText.App.agent}
              value={username}
              disabled
            />
            <TextField
              sx={{ marginBottom: 2 }}
              onChange={saveTitle}
              label={copyText.Feedback.title}
            />
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="feedback-description">{copyText.Feedback.Description}</InputLabel>
              <Select
                labelId="feedback-description"
                value={selectedFeedbackType}
                label={copyText.Feedback.Description}
                onChange={selectFeedbackType}
              >
                {feedbackTypes.map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              onChange={saveComments}
              label={copyText.Feedback.comments}
              multiline
              rows={8}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3, backgroundColor: 'lightGrayBG' }}>
          <Button
            sx={{ paddingLeft: 3, paddingRight: 3, mr: 3 }}
            color="secondary"
            variant="outlined"
            onClick={() => setShowModal(false)}
          >
            {copyText.App.cancel}
          </Button>
          <Button
            sx={{ paddingLeft: 4, paddingRight: 4 }}
            variant="contained"
            onClick={sendFeedbackRequest}
            disabled={loading}
          >
            <TextLoader loading={loading} size={25} text={copyText.Feedback.send} />
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        onClose={handleSnackbarClose}
        message={feedbackResponse}
        action={action}
      />
    </>
  );
};
Feedback.propTypes = {
  username: string.isRequired,
};

export default Feedback;
