import React from 'react';
import { Typography } from '@mui/material';
import { logError } from 'utils/errorUtils';
import { copyText } from 'language';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    logError({ src: 'ErrorBoundary component', error });
    return { hasError: true };
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // Render an error message or fallback UI
      return <Typography>{copyText.Page404.somethingWrong}</Typography>;
    }
    // Render the children components
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
