import { useState } from 'react';
import {
  useSetCurrentPage,
  useSetFoundOrderData,
  useOrderInput,
  useSelectedStore,
  useSetSelectedOrder,
  useSetOrderPayment,
} from 'contexts/orders';
import OrdersService from 'dataAccess/api/orders.ts';
import PaymentService from 'dataAccess/api/payments.ts';
import storeLocation from 'utils/storeLocation';
// ToDo: refactor code to be in utils
const UseOrderSearchFunctions = () => {
  const setCurrentPage = useSetCurrentPage();
  const setFoundOrderData = useSetFoundOrderData();
  const setSelectedOrder = useSetSelectedOrder();
  const setOrderPayment = useSetOrderPayment();
  const orderInput = useOrderInput();
  const selectedStore = useSelectedStore();
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('');

  const searchTypes = {
    email: 'email',
    orderNumber: 'orderNumber',
    allStoreOrders: 'allStoreOrders',
    todaysStoreOrders: 'todaysStoreOrders',
  };

  const useTodaysStoreOrders = async (pageInput = 1) => {
    setCurrentPage(pageInput);
    const storeKey = storeLocation.getStoreKey();
    const result = await OrdersService.getTodaysStoreOrders(storeKey, pageInput);
    setFoundOrderData(result.orders);
  };

  const useGetByOrderNumber = async (orderNumber) => {
    setCurrentPage(1);
    const result = await OrdersService.getByOrderNumber(orderNumber);
    if (result?.order) {
      setFoundOrderData({ results: [result.order], total: 1 });
    } else {
      setFoundOrderData({ results: [], total: 0 });
    }
  };

  const useLoadOrderByNumber = async (orderNumber) => {
    const result = await OrdersService.getByOrderNumber(orderNumber);
    if (result?.order) {
      setSelectedOrder(result.order);
    }
  };

  const useGetByEmail = async (email, pageInput = 1) => {
    setCurrentPage(pageInput);
    const result = await OrdersService.getByEmail(email, pageInput);
    setFoundOrderData(result.orders);
  };

  const useGetByStore = async (pageInput = 1) => {
    setCurrentPage(pageInput);
    setSearchType(searchTypes.allStoreOrders);
    const result = await OrdersService.getByStoreKey(selectedStore.key, pageInput);
    setFoundOrderData(result.orders);
    setLoading(false);
  };
  // ToDo: separate out into a populate function
  const useGetPaymentInfo = async (selectedOrder) => {
    // Retrieves the payment calls to get payment info.
    const payments = selectedOrder?.paymentInfo?.payments ?? [];
    const paymentResponse = await Promise.all(
      payments.map(async (payment) => {
        return PaymentService.getPaymentById(payment.id);
      }),
    );
    const newPaymentArray = paymentResponse.map((payment) => payment.payment);
    setOrderPayment(newPaymentArray);
    return newPaymentArray;
  };

  const isEmail = (input) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/;
    return emailRegex.test(input);
  };

  const useSearchUserInput = async (input = orderInput) => {
    try {
      setLoading(true);
      if (isEmail(input)) {
        setSearchType(searchTypes.email);
        await useGetByEmail(input);
        setLoading(false);
      } else if (input) {
        setSearchType(searchTypes.orderNumber);
        useGetByOrderNumber(input);
        setLoading(false);
      } else {
        setSearchType(searchTypes.allStoreOrders);
        useGetByStore();
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  /**
   * This Function calls the MPOS API based on the last search that was performed.
   * @param {number} page - The page of the paginated orders to be accessed by MPOS API
   *
   */
  const useHandlePageChange = (page) => {
    switch (searchType) {
      case searchTypes.email:
        useGetByEmail(orderInput, page + 1);
        break;
      case searchTypes.orderNumber:
        useGetByOrderNumber(orderInput);
        break;
      case searchTypes.allStoreOrders:
        useGetByStore(page + 1);
        break;
      case searchTypes.todaysStoreOrders:
        useTodaysStoreOrders(page + 1);
        break;
      default:
    }
  };

  const getOrderLocation = async (order) => {
    const locationList = await storeLocation.getStoreLocationsList();
    let orderLocation = storeLocation.getStoreNameByKey(order?.store?.key, locationList);
    if (order.custom?.fields?.source === 'web') {
      orderLocation = 'Web';
    }
    return orderLocation;
  };

  /**
   * This function formats the order data so that it can be displayed in a table format.
   * @param {array} orderData - An array of orders returned by the MPOS API.
   * @returns array of rows to be displayed.
   * {id - The identifying field to be displayed in the first column.
   *  columns - Array of the data that is going to be displayed in the order that it is going to be displayed.
   *  data - original order data with extra data that is not going to be displayed.
   * }
   */
  const useFormatOrderTableData = async (orderData) => {
    const tableData = await Promise.all(
      orderData.map(async (order) => {
        const orderLink = `order/${order.orderNumber}`;
        const orderDate = new Date(order.createdAt).toLocaleDateString();
        const customerName = `${order.shippingAddress?.firstName} ${order.shippingAddress?.lastName}`;
        const customerEmail = order.customerEmail;
        const orderLocation = await getOrderLocation(order);

        return {
          id: order.orderNumber,
          link: orderLink,
          columns: [orderDate, customerName, customerEmail, orderLocation],
          data: order,
        };
      }),
    );
    return tableData;
  };

  return {
    useTodaysStoreOrders,
    useSearchUserInput,
    useHandlePageChange,
    useFormatOrderTableData,
    useLoadOrderByNumber,
    useGetPaymentInfo,
    loading,
  };
};

export default UseOrderSearchFunctions;
