import axios from 'axios';
import { logError } from 'utils/errorUtils';
import getAccessToken from 'utils/getAccessToken';

const getStores = async (brickStores: boolean = true) => {
  const accessToken = getAccessToken();
  const uri = `${process.env.REACT_APP_MS_URL}/stores/?where=custom(fields(available = true))&perPage=100`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    params: {},
  };
  try {
    let result = await axios.get(uri, config);
    if (brickStores) {
      // filter out virtual store locations
      result = result.data.results.filter((store) => store?.supplyChannels?.length > 0);
    }
    return result;
  } catch (error) {
    logError({
      method: 'getStores',
      errorInfo: error,
      message: error?.message,
      source: 'api/stores',
    });
    return false;
  }
};

export default { getStores };
