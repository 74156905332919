import locationUtil from 'utils/storeLocation';
import { shape, arrayOf, string, object, func, bool } from 'prop-types';
import {
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Typography,
} from '@mui/material';
import { copyText, lang } from 'language';
import ClearCartBtn from 'components/ClearCart/components/ClearCartBtn';
import { useEffect, useState } from 'react';

const SelectLocation = ({
  selectedStore,
  setSelectedStore,
  storeList,
  updateSelectedStore,
  openModal,
  closeModal,
}) => {
  const [cancelDisabled, setCancelDisabled] = useState(false);
  const handleClose = (event, reason) => {
    // Disable closing modal from clicking backdrop
    if (reason && reason === 'backdropClick') {
      return;
    }
    if (selectedStore && selectedStore.key) {
      updateSelectedStore(selectedStore);
      closeModal(true);
    }
  };

  const handleSelectedStore = (store) => {
    setSelectedStore(store);
  };

  const handleCancel = async () => {
    if (selectedStore) {
      const prevStore = await locationUtil.getLocationStorage();
      setSelectedStore(prevStore);
      closeModal(true);
    }
  };

  const checkDisableCancel = async () => {
    const prevStore = await locationUtil.getLocationStorage();
    if (prevStore) {
      setCancelDisabled(false);
    } else {
      setCancelDisabled(true);
    }
  };

  useEffect(() => {
    checkDisableCancel();
  }, [openModal]);

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{copyText.Location.selectLocation}</DialogTitle>
      <DialogContent>
        <Select fullWidth value={selectedStore?.id || ''} label={copyText.Location.select}>
          {storeList.map((store) => (
            <MenuItem key={store.id} onClick={() => handleSelectedStore(store)} value={store.id}>
              {store.name[lang]}
            </MenuItem>
          ))}
        </Select>
        <Alert severity="warning" sx={{ mt: 3 }}>
          <Typography>{copyText.Cart.CartTools.cartsAssociatedToStores}</Typography>
          <Typography>{copyText.Cart.CartTools.storeChangeWarning}</Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mr: 2 }}
          disabled={!selectedStore || cancelDisabled}
          onClick={handleCancel}
          variant="outlined"
        >
          {copyText.App.cancel}
        </Button>
        <ClearCartBtn
          preEvent={() => updateSelectedStore(selectedStore)}
          onComplete={handleClose}
          disabled={!selectedStore}
          text={copyText.App.chooseStore}
        />
      </DialogActions>
    </Dialog>
  );
};

SelectLocation.propTypes = {
  selectedStore: shape({ id: string }),
  setSelectedStore: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  storeList: arrayOf(shape({ id: string, name: object })),
  updateSelectedStore: func.isRequired,
  openModal: bool,
  closeModal: func.isRequired,
};

SelectLocation.defaultProps = {
  selectedStore: {
    id: null,
  },
  storeList: [{ id: null, name: { 'en-US': '' } }],
  openModal: false,
};

export default SelectLocation;
