import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  DialogTitle,
  Snackbar,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { copyText } from 'language';
import TextLoader from 'components/TextLoader';
import cartGiftcard from 'dataAccess/api/cart.giftcard.ts';
import { useCart, useSetCart } from 'contexts';

const GiftCard = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [giftCardNumber, setGiftCardNumber] = useState();
  const [securityCode, setSecurityCode] = useState();
  const handleCloseModal = () => {
    setOpen(false);
    setGiftCardNumber(null);
    setSecurityCode(null);
  };

  const getError = (err) => {
    return err?.response?.data?.errors[0]?.meta?.error || err?.response?.data?.errors[0]?.title;
  };

  const addGiftCardNumber = (e) => {
    setGiftCardNumber(e.target.value);
  };
  const addSecurityCode = (e) => {
    setSecurityCode(e.target.value);
  };

  const applyBalance = async () => {
    try {
      setLoading(true);

      const giftCard = {
        giftCardNumber,
        securityCode,
      };
      const result = await cartGiftcard.addGiftCardToCart(cart.id, giftCard);
      setCart(result.data);
      setLoading(false);
      setOpen(false);
      setStatus(copyText.Cart.CartTools.successfulGiftCard);
      setOpenSnackbar(true);
      setGiftCardNumber(null);
      setSecurityCode(null);
    } catch (err) {
      setLoading(false);
      setStatus(`${copyText.Cart.CartTools.failedGiftCard}: ${getError(err)}`);
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <>
      <Button onClick={() => setOpen(true)} sx={{ height: '20px', p: 1 }} color="secondary">
        {copyText.App.add}
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle sx={{ backgroundColor: 'lightGrayBG' }}>
          <Typography variant="h5" component="h5">
            {copyText.Cart.CartTools.applyGiftCard}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2, mt: 2 }}
            onChange={addGiftCardNumber}
            fullWidth
            placeholder={copyText.Cart.CartTools.giftCardNumber}
          />
          <TextField
            placeholder={copyText.Cart.CartTools.giftCardSecurityCode}
            onChange={addSecurityCode}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'lightGrayBG', p: 3 }}>
          <Button variant="outlined" color="secondary" sx={{ mr: 2 }} onClick={handleCloseModal}>
            {copyText.App.cancel}
          </Button>
          <Button
            variant="contained"
            onClick={applyBalance}
            disabled={loading || !securityCode || !giftCardNumber}
          >
            <TextLoader text={copyText.Cart.CartTools.applyBalance} loading={loading} size={20} />{' '}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={status}
        action={action}
      />
    </>
  );
};

export default GiftCard;
