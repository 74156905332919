import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { copyText } from 'language';
import { logError } from 'utils/errorUtils';
/**
 * addDiscountCode - Adds a discount to a cart by code
 * @param cartId the CT id of the cart
 * @param code A valid discount code
 * @returns An updated cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1code/post
 */
const addDiscountToCart = async (cartId: string, code: string) => {
  const hasRequiredParams = !!cartId && !!code;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    try {
      // const oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/code`;
      const reqBody = {
        code,
      };
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.post(uri, JSON.stringify(reqBody), config);
      return response.data;
    } catch (error) {
      logError({
        method: 'addDiscountToCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
      throw new Error(
        error.response?.data.errors[0]?.meta?.error || copyText.Cart.PromoCode.failedToAddPromo,
      );
    }
  }

  throw new Error('Add discount code requires: cartId, code');
};

/**
 * removeDiscountCode - Removes a discount by code from a given cart
 * @param cartId the CT id of the cart
 * @param code a valid discount code
 * @returns An updated cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1code~1{discountCode}/delete
 */
const removeDiscountFromCart = async (cartId: string, code: string) => {
  const hasRequiredParams = !!cartId && !!code;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/code/${code}`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.delete(uri, config);
      return response;
    } catch (error) {
      logError({
        method: 'removeDiscountFromCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }

  throw new Error('Remove discount code requires: cartId, code');
};

const uri = `${process.env.REACT_APP_PIM_BASE_URL}/coupon/cart`;
const generateSingleUsePromo = async (
  couponName: string,
  discountValueType: string,
  discountValue: number,
  discountStacking: boolean,
) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams = !!couponName && !!discountValueType && discountValue;
  if (hasRequiredParams) {
    try {
      const req = {
        couponName,
        discountValueType,
        discountValue,
        discountStacking,
      };

      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const reqBody = JSON.stringify(req);
      const result = await axios.post(uri, reqBody, config);
      return result.data;
    } catch (error) {
      logError({
        method: 'generateSingleUsePromo',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }
  throw new Error('Coupon Generation requires: couponName, discountType, discountAmount');
};

interface lineItemParams {
  couponName: string;
  sku: string;
  discountValueType: string;
  discountValue: number;
  discountQuantity: number;
  discountStacking: boolean;
}
const productUri = `${process.env.REACT_APP_PIM_BASE_URL}/coupon/product`;
const generateLineItemPromo = async (input: lineItemParams) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams =
    !!input.couponName && !!input.discountValueType && input.discountValue && input.sku;
  if (hasRequiredParams) {
    try {
      const req = {
        couponName: input.couponName,
        discountValueType: input.discountValueType,
        discountValue: input.discountValue,
        sku: input.sku,
        discountQuantity: input.discountQuantity,
        discountStacking: input.discountStacking,
      };

      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const reqBody = JSON.stringify(req);
      const result = await axios.post(productUri, reqBody, config);
      return result.data;
    } catch (error) {
      logError({
        method: 'generateLineItemPromo',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }
  throw new Error('Coupon Generation requires: couponName, discountType, discountAmount, sku');
};

export default {
  addDiscountToCart,
  removeDiscountFromCart,
  generateSingleUsePromo,
  generateLineItemPromo,
};
