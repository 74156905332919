import { lang } from 'language';
import convertCentToDollar from './convertCentToDollar';
import financialCalculators from './financialCalculators/financialCalculators';
// import storeLocation from './storeLocation';

const getDescription = (variant) => {
  const desc = variant.attributes.find((attr) => attr.name === 'title');
  const colorDesc = variant.attributes.find((attr) => attr.name === 'color');
  let description = desc?.value ?? '';
  if (colorDesc && colorDesc.value && colorDesc?.value?.label) {
    description = `${desc?.value} | ${colorDesc?.value?.label}`;
  }
  return description;
};

const getStrikethroughPrice = (lineItem) => {
  const basePrice = lineItem.price.value.centAmount;
  let discountPrice = basePrice;
  const discounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
  if (discounts.length > 0) {
    discountPrice = discounts.reduce((acc, discount) => {
      return acc + discount.cent_amount;
    }, basePrice);
  }
  return discountPrice !== basePrice ? discountPrice : null;
};

const formatLineItems = (lineItems) => {
  const items = [];
  lineItems.forEach((lineItem) => {
    const price = convertCentToDollar(lineItem.totalPrice.centAmount) ?? '';
    const description = getDescription(lineItem.variant);
    const strikeThroughPrice = getStrikethroughPrice(lineItem)
      ? convertCentToDollar(getStrikethroughPrice(lineItem) * lineItem.quantity)
      : null;
    const item = {
      product_id: lineItem.productId ?? '',
      sku: lineItem?.variant.sku ?? '',
      name: lineItem?.name[lang] ?? '',
      description: description ?? '',
      qty: lineItem?.quantity ?? 0,
      price,
      strike_through_price: strikeThroughPrice ?? '',
      image: lineItem?.variant?.images[0]?.url ?? '',
    };
    items.push(item);
  });
  return items;
};

// SUNSET: ToddF Remove if not needed for draft orders
// const getStoreData = async (storeKey) => {
//   const storeData = await storeLocation.getStoreByKey(storeKey);
//   const storeDetails = storeData?.supplyChannels[0]?.obj;
//   const storeParams = {
//     name: storeDetails?.name[lang] ?? '',
//     streetAddress: storeDetails?.address?.streetName ?? '',
//     streetNumber: storeDetails?.address?.streetNumber ?? '',
//     postalCode: storeDetails?.address?.postalCode ?? '',
//     city: storeDetails?.address?.city ?? '',
//     state: storeDetails?.address?.state ?? '',
//     description: storeDetails?.description[lang] ?? '',
//     phone: storeDetails?.phone ?? '',
//     openTime: storeDetails?.hours?.open ?? '',
//     closeTime: storeDetails?.hours?.close ?? '',
//     key: storeDetails?.key ?? '',
//   };
//   return storeParams;
// };

const formatDraftOrderItems = async (email, cart, okta, note, customerName) => {
  const agentEmail = okta.authStateManager?._authState?.idToken?.claims?.email ?? '';
  const agentName = okta.authStateManager?._authState?.idToken?.claims?.name ?? '';
  const subtotal = convertCentToDollar(financialCalculators.getSubtotal(cart));
  const tax = convertCentToDollar(financialCalculators.getTax(cart));
  const shipping = convertCentToDollar(financialCalculators.getShippingTotals(cart));
  const discounts = convertCentToDollar(financialCalculators.getCartDiscounts(cart));
  const orderTotal = convertCentToDollar(financialCalculators.getTotalPrice(cart));
  const lineItems = formatLineItems(cart.lineItems);
  // let storeData = {};
  // if (cart && cart.store && cart.store.key) {
  //   storeData = await getStoreData(cart.store.key);
  // }
  const params = {
    agentName,
    agentEmail,
    agentNote: note ?? '',
    customerEmail: email ?? '',
    customerFirstName: customerName ?? '',
    cartId: cart?.id,
    subtotal,
    tax,
    shipping,
    discounts,
    orderTotal,
    lineItems,
    storeKey: cart?.store?.key ?? '',
  };
  return params;
};

export default {
  formatDraftOrderItems,
  getDescription,
};
