import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { FeedbackBody } from './interfaces/feedbackInterface';

/**
 * --- Jira documentation ---
 * API: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issues/#api-rest-api-3-issue-post
 * Atlassian Document Format: https://developer.atlassian.com/cloud/jira/platform/apis/document/structure/#json-structure
 */

const formatErrorLogs = (logs) => {
  const formatted = [];

  // --- Format latest 25 log entries ---
  logs.slice(-25).forEach((item) => {
    formatted.push({
      type: 'text',
      text: `${item}\n`,
    });
  });

  // --- Assemble formatted error logs ---
  const errorLogs = [
    // --- Headings for markdown formatting ---
    {
      type: 'text',
      text: '| Timestamp | Method | Source | Message | Error Info |\n',
    },
    {
      type: 'text',
      text: '| --------- | ------ | ------ | ------- | ---------- |\n',
    },
    ...formatted,
  ];

  return errorLogs;
};

const formatBody = (feedbackForm: FeedbackBody) => {
  const errorLogs = formatErrorLogs(feedbackForm.errorLogs);

  // --- Assemble payload body ---
  const body = {
    fields: {
      project: {
        key: 'MPOS',
      },
      summary: `FEEDBACK | ${feedbackForm.selectedFeedbackType} | ${feedbackForm.title}`,
      description: {
        type: 'doc',
        version: 1,
        content: [
          // --- Agent Name ---
          {
            type: 'heading',
            attrs: {
              level: 4,
            },
            content: [
              {
                text: 'Agent Name',
                type: 'text',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: `${feedbackForm.agent}`,
              },
            ],
          },

          // --- Store Name ---
          {
            type: 'heading',
            attrs: {
              level: 4,
            },
            content: [
              {
                text: 'Store Name',
                type: 'text',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: `${feedbackForm.storeName}`,
              },
            ],
          },

          // --- Page URL ---
          {
            type: 'heading',
            attrs: {
              level: 4,
            },
            content: [
              {
                text: 'Page URL',
                type: 'text',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: `${feedbackForm.webPage}`,
              },
            ],
          },

          // --- Feedback Type ---
          {
            type: 'heading',
            attrs: {
              level: 4,
            },
            content: [
              {
                text: 'Feedback Type',
                type: 'text',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: `${feedbackForm.selectedFeedbackType}`,
              },
            ],
          },

          // --- Comments ---
          {
            type: 'heading',
            attrs: {
              level: 4,
            },
            content: [
              {
                text: 'Comments',
                type: 'text',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: `${feedbackForm.comments}`,
              },
            ],
          },

          // --- Error Logs ---
          {
            type: 'heading',
            attrs: {
              level: 4,
            },
            content: [
              {
                text: 'Error Logs (RAW)',
                type: 'text',
              },
            ],
          },
          {
            type: 'codeBlock',
            attrs: {
              language: 'markdown',
            },
            content: errorLogs,
          },
        ],
      },
      issuetype: {
        name: 'Story',
      },
    },
  };
  return body;
};

const sendFeedBack = async (feedbackForm: FeedbackBody) => {
  if (
    feedbackForm.title &&
    feedbackForm.comments &&
    feedbackForm.agent &&
    feedbackForm.storeName &&
    feedbackForm.webPage
  ) {
    const accessToken = getAccessToken();
    const body = formatBody(feedbackForm);

    try {
      const uri = `${process.env.REACT_APP_PIM_BASE_URL}/feedback`;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const result = await axios.post(uri, body, config);
      return result;
    } catch (error) {
      return error;
    }
  } else {
    throw new Error(
      'Feedback request requires a Page, Store, Agent, Title, Description, and Comments',
    );
  }
};

export default {
  sendFeedBack,
};
