import { Grid, Skeleton, Typography } from '@mui/material';
import { copyText } from 'language';
import { shape } from 'prop-types';

const ShippingDetails = ({ product }) => {
  return product ? (
    <Grid item xs={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontSize: '1.2rem' }}>
            {copyText.Shop.PDP.details.shippingDetails}
          </Typography>
        </Grid>
        {product.attributes['ship-length'] && (
          <Grid item xs={12}>
            <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
              {copyText.Shop.PDP.details.shippingDimensions}
            </Typography>
            <Typography>
              {`${product.attributes['ship-length']}" x `}
              {`${product.attributes['ship-height']}"`}
            </Typography>
          </Grid>
        )}
        {product.attributes['ship-weight'] && (
          <Grid item xs={12}>
            <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
              {copyText.Shop.PDP.details.shippingWeight}
            </Typography>
            <Typography variant="p">{`${product.attributes['ship-weight']} lbs`}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontSize: '1.2rem' }}>
            <Skeleton />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
            <Skeleton />
          </Typography>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
            <Skeleton />
          </Typography>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ShippingDetails.propTypes = {
  product: shape({
    attributes: shape({}).isRequired,
  }),
};

ShippingDetails.defaultProps = {
  product: null,
};

export default ShippingDetails;
