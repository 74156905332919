import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { logError } from 'utils/errorUtils';
import { GiftCardParams } from './interfaces/cartEndpointInterfaces';

/**
 * addGiftCardToCart - Adds a valid gift card to the cart
 * @param cartId the CT id for the cart
 * @param giftCard the card number and security code
 * @returns An updated cart of undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1gift-card/post
 */
const addGiftCardToCart = async (cartId: string, giftCard: GiftCardParams) => {
  const hasRequiredParams =
    !!cartId && !!giftCard && !!giftCard.giftCardNumber && !!giftCard.securityCode;

  const oktaToken = getAccessToken();
  if (hasRequiredParams) {
    try {
      // const oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/gift-card`;
      const reqBody = { ...giftCard };
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.post(uri, JSON.stringify(reqBody), config);
      return response;
    } catch (error) {
      logError({
        method: 'addGiftCardToCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.giftcards',
      });
    }
  }

  throw new Error('Add gift card requires: cartId, giftCardNumber, securityCode');
};

/**
 * getGiftCardBalance - Gets the balance given a gift card
 * @param giftCard the number and code of the giftcard
 * @returns A balance for a given gift card
 * @link https://onpurple.github.io/carts-ms/#/paths/~1gift-card-balance/post
 */
const getGiftCardBalance = async (giftCard: GiftCardParams, oktaToken?: string) => {
  const hasRequiredParams = !!giftCard && !!giftCard.giftCardNumber && !!giftCard.securityCode;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/gift-card-balance`;
      const reqBody = { ...giftCard };
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.post(uri, JSON.stringify(reqBody), config);
      return response;
    } catch (error) {
      logError({
        method: 'getGiftCardBalance',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.giftcards',
      });
    }
  }

  throw new Error('Get gift card balance requires: giftCardNumber, securityCode');
};

/**
 * removeGiftCardFromCart - Removes a gift card via number from a given cart
 * @param cartId the CT Id for the cart
 * @param giftCardNumber the gift card number to remove
 * @returns an updated Cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1gift-card~1{giftCardNumber}/delete
 */
const removeGiftCardFromCart = async (
  cartId: string,
  giftCardNumber: string,
  oktaToken?: string,
) => {
  const hasRequiredParams = !!cartId && !!giftCardNumber;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    try {
      // const oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/gift-card/${giftCardNumber}`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.delete(uri, config);
      return response;
    } catch (error) {
      logError({
        method: 'removeGiftCardFromCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.giftcards',
      });
    }
  }

  throw new Error('removing a gift card requires: cartId, giftCardNumber');
};

/**
 * removeAllGiftCardsFromCart - Removes all gift cards from a given cart
 * @param cartId the CT id for the cart
 * @returns an updated Cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1gift-card/delete
 */
const removeAllGiftCardsFromCart = async (cartId: string, oktaToken?: string) => {
  const hasRequiredParams = !!cartId;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    try {
      // const oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/gift-card`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.delete(uri, config);
      return response;
    } catch (error) {
      logError({
        method: 'removeAllGiftCardsFromCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.giftcards',
      });
    }
  }

  throw new Error('removing all gift cards requires: cartId');
};

export default {
  addGiftCardToCart,
  removeGiftCardFromCart,
  removeAllGiftCardsFromCart,
  getGiftCardBalance,
};
