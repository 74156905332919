import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { copyText } from 'language';
import React, { useState } from 'react';

const RefundModalTEMP = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button onClick={() => setShow(true)} variant="outlined">
        {copyText.Orders.RefundModal.refund}
      </Button>
      <Dialog fullWidth open={show}>
        <DialogTitle sx={{ backgroundColor: 'lightGrayBG', mb: 3 }} variant="h4">
          {copyText.Orders.RefundModal.refund}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" sx={{ mb: 3 }}>
            {copyText.Orders.RefundModal.instructions}
          </Typography>
          <Typography variant="subtitle">{copyText.Orders.RefundModal.patience}</Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, mt: 2, backgroundColor: 'lightGrayBG' }}>
          <Button variant="outlined" sx={{ mr: 2, pl: 3, pr: 3 }} onClick={() => setShow(false)}>
            {copyText.App.close}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RefundModalTEMP;
