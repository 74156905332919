import { Grid } from '@mui/material';
import React from 'react';
import { useCart } from 'contexts';
import CartFinancials from './components/CartFinancials/CartFinancials';
import CustomerInfo from './components/CustomerInfo/CustomerInfo';

const RightColumn = () => {
  const cart = useCart();
  return (
    <Grid item xs={16} sm={7} md={6} sx={{ mt: 4 }}>
      {cart?.id && <CustomerInfo />}
      <CartFinancials />
    </Grid>
  );
};

export default RightColumn;
