import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { copyText } from 'language';
import storeLocation from 'utils/storeLocation';
import { arrayOf, func, shape, string } from 'prop-types';
import { useSetReturnMessage } from 'contexts/orders';

const ReturnForm = (props) => {
  const { orderInfo, returnItems, closeModal } = props;
  const { shippingAddress } = orderInfo;
  const setReturnMessage = useSetReturnMessage();
  const history = useHistory();
  const customerFullName = `${shippingAddress?.firstName} ${shippingAddress?.lastName}`;
  const iframeRef = useRef(null);
  const iframeSrc = 'https://survey.comfort-research.org/jfe/form/SV_43pGEWUlZJrDaHs';

  const { oktaAuth } = useOktaAuth();

  const getAgentName = () => {
    const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
    if (name) {
      return name;
    }
    return copyText.Agent.notAvailable;
  };
  const getAgentEmail = () => {
    const email = oktaAuth.authStateManager?._authState?.idToken?.claims?.email;
    return email || copyText.Agent.notAvailable;
  };

  const formatFormData = () => {
    const locationAddress = storeLocation.getStoreAddress();

    const data = {
      agent_name: getAgentName(),
      agent_email: getAgentEmail(),
      store_code: storeLocation.getStoreKey(),
      order_number: orderInfo.orderNumber,
      customer_email: orderInfo.customerEmail,
      customer_name: customerFullName,
      is_return: true,
      store_address_1: locationAddress.streetName,
      store_suite: locationAddress.streetNumber,
      store_city: locationAddress.city,
      store_state: locationAddress.state,
      store_zip: locationAddress.postalCode,
      store_country: locationAddress.country,
    };
    returnItems.forEach((item, index) => {
      data[`item_${index + 1}`] = item.sku;
      data[`item_${index + 1}_quantity`] = item.quantity;
    });
    return data;
  };

  const autoFill = () => {
    // Get qualtrics form from iframe
    const qualtricsForm = document.getElementById('qualtrics-returns').contentWindow;
    const autoFillData = Object.entries(formatFormData());
    // Send input value to qualtrics form
    if (qualtricsForm) {
      autoFillData.forEach((valuePair) => {
        const message = {
          event: 'autofill',
          target: valuePair[0],
          value: valuePair[1],
        };
        qualtricsForm.postMessage(JSON.stringify(message), '*');
      });
    }
  };

  useEffect(() => {
    // Reference to the iframe.
    const iframe = iframeRef.current;
    // If the Iframe has loaded perform the autofill functionality.
    if (iframe) {
      window.addEventListener('message', (event) => {
        // eslint-disable-next-line no-useless-catch
        try {
          if (event.data === 'closeQSIWindow') {
            closeModal();
            setReturnMessage(true);
            history.push(`/order/${orderInfo.orderNumber}`);
          }

          const request = JSON.parse(event.data);
          if (request.event === 'canScreenCapture') {
            autoFill();
          }
        } catch (error) {
          throw error;
        }
      });
    }
  }, [iframeRef]);

  return (
    <iframe
      id="qualtrics-returns"
      ref={iframeRef}
      title="return"
      src={iframeSrc}
      height="600px"
      width="100%"
    />
  );
};

export default ReturnForm;

ReturnForm.propTypes = {
  orderInfo: shape({
    orderNumber: string,
    shippingAddress: shape({
      firstName: string,
      lastName: string,
    }),
  }),
  returnItems: arrayOf(shape({ sku: string, quantity: string })),
  closeModal: func.isRequired,
};

ReturnForm.defaultProps = {
  returnItems: [],
  orderInfo: {},
};
