import React, { useEffect, useState } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { Typography, CardContent, Box, Grid, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';
import ProductImage from 'components/ProductImage/ProductImage';
import AddToCartBtn from 'components/AddToCartBtn/AddToCartBtn';
import convertCentToDollar from 'utils/convertCentToDollar';

const ProductCard = ({ imgUrl, details, labels, productLink }) => {
  const { desc, price } = details;
  const [basePrice, setBasePrice] = useState('');
  const getPrices = () => {
    const dollarPrice = convertCentToDollar(price[0]?.value.centAmount);
    if (dollarPrice !== '$NaN') {
      setBasePrice(dollarPrice);
    }
    // TODO: discount price for strikethrough pricing
  };
  useEffect(() => {
    getPrices();
  }, [details]);
  return (
    <Box sx={{ maxWidth: 290, minWidth: 290 }}>
      <ProductImage imgUrl={imgUrl} labels={labels} />
      <CardContent sx={{ pl: 0 }}>
        <Grid sx={{ mb: 1 }}>
          <Link component={RouterLink} to={productLink} underline="hover">
            <Typography variant="h6" component="span">
              {desc}{' '}
              <ArrowForwardIcon
                color="secondary"
                fontSize="medium"
                sx={{ verticalAlign: 'middle' }}
              />
            </Typography>
          </Link>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {details?.sku}
          </Typography>
        </Grid>
        {/* <Grid container display="flex">
          // This will be used in a future intiative to add strikethrough
          <Typography
            sx={{ fontWeight: 600, textDecoration: 'line-through', fontSize: 18, mr: 2 }}
            component="span"
          >
            {basePrice}
          </Typography>
        </Grid> */}
        <Grid
          container
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          sx={{ textAlign: 'center' }}
        >
          <AddToCartBtn details={details} />
          <Typography sx={{ fontWeight: 600, fontSize: 18 }} component="span">
            {basePrice}
          </Typography>
        </Grid>
      </CardContent>
    </Box>
  );
};

ProductCard.propTypes = {
  imgUrl: string,
  details: shape({
    sku: string.isRequired,
    title: string,
    price: string,
    desc: string,
  }).isRequired,
  labels: arrayOf(string),
  productLink: string.isRequired,
};

ProductCard.defaultProps = {
  imgUrl: null,
  labels: [],
};

export default ProductCard;
