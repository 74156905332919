import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { useCart } from 'contexts';
import copyText from 'language/enUS';
import { arrayOf, bool, func, shape, string } from 'prop-types';

const OrderFailureDialog = ({ open, onClose, errorMessage, onRetry }) => {
  const cart = useCart();
  const retryRequest = () => {
    onClose();
    onRetry();
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {cart?.is_exchange_order
          ? copyText.Cart.CartTools.exchangeOrderCreationFailed
          : copyText.Cart.CartTools.orderCreationFailed}
        <Typography>
          <strong>{copyText.Cart.CartTools.reason}:</strong>{' '}
          {errorMessage?.response?.data?.errors[0]?.detail || copyText.App.errorOccurred}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <Typography>{copyText.Cart.CartTools.canRetry}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="outlined"
          sx={{ display: 'block', displayPrint: 'none', mr: 2 }}
          onClick={onClose}
        >
          {copyText.App.close}
        </Button>
        <Button
          variant="contained"
          sx={{ display: 'block', displayPrint: 'none' }}
          onClick={retryRequest}
          // onClick={() => window.print()}
        >
          {copyText.App.retry}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrderFailureDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onRetry: func.isRequired,
  errorMessage: shape({
    response: shape({
      data: shape({
        errors: arrayOf(
          shape({
            detail: string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default OrderFailureDialog;
