import { InfoOutlined } from '@mui/icons-material';
import { ListItem, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { bool, string } from 'prop-types';
import MobileTooltip from './MobileTooltip/MobileTooltip';

const FinanceListItem = ({
  showDefinition,
  definition,
  boldLabel,
  boldValue,
  label,
  value,
  isPromo,
}) => {
  return (
    <ListItem sx={{ color: isPromo ? 'darkAqua' : '', p: 0, justifyContent: 'space-between' }}>
      <Box display="flex" alignItems="center">
        <Typography
          variant={boldLabel ? 'list_item_bold_title' : 'list_item'}
          component="p"
          align="left"
        >
          {label}
        </Typography>
        {showDefinition && <MobileTooltip title={definition} />}
      </Box>
      <Typography
        variant={boldValue ? 'list_item_bold_title' : 'list_item'}
        component="p"
        align="right"
      >
        {value}
      </Typography>
    </ListItem>
  );
};

FinanceListItem.propTypes = {
  boldLabel: bool,
  boldValue: bool,
  isPromo: bool,
  label: string.isRequired,
  showDefinition: bool,
  definition: string,
  value: string.isRequired,
};

FinanceListItem.defaultProps = {
  boldLabel: false,
  boldValue: false,
  isPromo: false,
  showDefinition: false,
  definition: '',
};

export default FinanceListItem;
