import React, { useState, useEffect } from 'react';
import { List, ListItem, Typography, IconButton, Snackbar } from '@mui/material';
import plural from 'utils/PluralProducts';
import { useSetCart } from 'contexts';
import convertCentToDollar from 'utils/convertCentToDollar';
import CloseIcon from '@mui/icons-material/Close';
import discountService from 'dataAccess/api/cart.discounts.ts';
import { copyText } from 'language';
import { arrayOf, bool, shape } from 'prop-types';
import { Box } from '@mui/system';
import TextLoader from 'components/TextLoader';
import { DeleteOutline } from '@mui/icons-material';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import getExchangeTotal from 'utils/CartFinancials/getExchangeTotal';
import FinanceListItem from 'components/FinanceListItem';
import GiftCard from './components/GiftCard';

const CartTotals = ({ cart, editGiftCards, editCoupons }) => {
  const lineHeight = 2;
  const setCart = useSetCart();
  const [totalPrice, setTotalPrice] = useState('$0.00');
  const [promoCodeTotals, setPromoCodeTotals] = useState('$0.00');
  const [discountCodes, setDiscountCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [giftCardTotals, setGiftCardTotals] = useState('$0.00');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [exchangeTotal, setExchangeTotal] = useState(0);
  const populateAppliedDiscountCodes = () => {
    const discountCodesArray = [];

    cart?.lineItems?.forEach((lineItem) => {
      const discounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
      discounts.forEach((item) => {
        const existingDiscount = discountCodesArray.find((ele) => ele.id === item.id);
        if (!existingDiscount) {
          discountCodesArray.push(item);
        }
      });
    });

    // calculate total discount for each discount code
    const updatedDiscountCodesArray = discountCodesArray.map((item) => {
      const updatedItem = { ...item };
      updatedItem.totalCumulativeDiscount = 0;

      cart?.lineItems?.forEach((lineItem) => {
        const lineItemDiscounts = JSON.parse(lineItem.custom?.fields?.discounts_json);

        lineItemDiscounts.forEach((discount) => {
          if (discount.id === item.id) {
            updatedItem.totalCumulativeDiscount += discount.cent_amount * lineItem.quantity || 0;
          }
        });
      });
      updatedItem.totalCumulativeDiscount = convertCentToDollar(
        updatedItem.totalCumulativeDiscount || 0,
      );

      return updatedItem;
    });

    setDiscountCodes(updatedDiscountCodesArray);
    return updatedDiscountCodesArray;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
    setErrorMessage('');
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const removeDiscountCode = async (code) => {
    try {
      setLoading(true);
      const result = await discountService.removeDiscountFromCart(cart.id, code.coupon_code);
      setCart(result.data);
    } catch {
      setShowError(true);
      setErrorMessage(copyText.Cart.PromoCode.removeError);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalPrice = () => {
    const price = cart?.totalPrice?.centAmount;
    const promos = financialCalculators.getCartDiscounts(cart);
    const shipping = financialCalculators.getShippingTotals(cart);
    const recycling = financialCalculators.getRecyclingFee(cart);
    const total = price + promos - shipping - recycling;

    return total;
  };

  const displayRemoveButton = (discount) => {
    if (discount.method === 'coupon') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setGiftCardTotals(convertCentToDollar(financialCalculators.getGiftCardTotals(cart)));
    setPromoCodeTotals(convertCentToDollar(financialCalculators.getCartDiscounts(cart)));
    setExchangeTotal(convertCentToDollar(getExchangeTotal(cart)));
    setTotalPrice(convertCentToDollar(calculateTotalPrice()));
    populateAppliedDiscountCodes();
  }, [cart]);

  return (
    <>
      <List sx={{ mb: 2 }}>
        <FinanceListItem
          label={`Price (${cart?.totalLineItemQuantity || 0} item${
            plural(cart?.totalLineItemQuantity || 0) ? 's' : ''
          })`}
          value={totalPrice}
        />
        <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
          <Typography variant="list_item" component="p" align="left">
            {copyText.Cart.CartTools.giftCards}
            {editGiftCards && <GiftCard />}
          </Typography>
          <Typography variant="list_item" component="p" align="right">
            -{giftCardTotals}
          </Typography>
        </ListItem>
        <FinanceListItem
          label={copyText.Cart.CartTools.promos}
          value={`-${promoCodeTotals}`}
          isPromo
        />
        {cart?.is_exchange_order && (
          <FinanceListItem
            label={copyText.Orders.Actions.exchangeCredit}
            value={`-${exchangeTotal}`}
            isPromo
            showDefinition
            definition={copyText.Cart.CartTools.exchangeCreditDefinition}
          />
        )}
        {discountCodes.length > 0 && (
          <Box sx={{ backgroundColor: 'lightPurple20', p: 1, borderRadius: '5px' }}>
            <ListItem sx={{ p: 0, justifyContent: 'space-between', mb: 1 }}>
              <Typography
                component="h3"
                variant="h4"
                align="left"
                fontSize={14}
                sx={{ lineHeight, fontWeight: 'bold', fontSize: 12 }}
              >
                {copyText.Cart.CartTools.appliedDiscounts}:
              </Typography>
            </ListItem>

            {discountCodes.map((discountCode) => {
              return (
                <ListItem key={discountCode.id} sx={{ p: 1, justifyContent: 'space-between' }}>
                  <Box display="flex" alignItems="center">
                    {editCoupons && displayRemoveButton(discountCode) && (
                      <IconButton
                        sx={{ p: 0, mr: 1 }}
                        onClick={() => removeDiscountCode(discountCode)}
                      >
                        <TextLoader loading={loading} size={20} text={<DeleteOutline />} />
                      </IconButton>
                    )}
                    <Typography align="left" variant="list_item">
                      {discountCode.display_name}
                    </Typography>
                  </Box>
                  <Typography align="left" variant="list_item">
                    -{discountCode.totalCumulativeDiscount}
                  </Typography>
                </ListItem>
              );
            })}
          </Box>
        )}
      </List>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={errorMessage}
        action={action}
      />
    </>
  );
};

CartTotals.propTypes = {
  cart: shape({
    lineItems: arrayOf(shape({})),
    taxedPrice: shape({}),
  }),
  editGiftCards: bool,
  editCoupons: bool,
};

CartTotals.defaultProps = {
  cart: null,
  editGiftCards: true,
  editCoupons: true,
};
export default CartTotals;
