import { useEffect, useState } from 'react';
import toDollar from 'utils/toDollar';

const calculateDiscounts = (selectedOrder) => {
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [discounts, setDiscounts] = useState([]);

  const getDiscounts = () => {
    const discountArray = [];
    selectedOrder.lineItems.forEach((item) => {
      const discountsJson = item.custom?.fields?.discounts_json;

      if (discountsJson) {
        const parsedDiscounts = JSON.parse(discountsJson);
        discountArray.push(...parsedDiscounts);
      }
    });

    setDiscounts(discountArray);

    const total = discountArray.reduce((acc, discount) => {
      if (discount.cent_amount) {
        return acc + discount.cent_amount;
      }
      return acc;
    }, 0);

    setTotalDiscounts(toDollar(total));
  };

  useEffect(() => {
    if (selectedOrder && selectedOrder.lineItems) {
      getDiscounts();
    }
  }, [selectedOrder]);

  return { discounts, totalDiscounts };
};

export default calculateDiscounts;
