import { Icon, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import DescriptionIcon from '@mui/icons-material/Description';
import { copyText } from 'language';
import { useCart } from 'contexts';

const SendDraft = ({ handleDialogSetOpen }) => {
  const cart = useCart();
  const [canSendDraft, setCanSendDraft] = useState(false);

  const checkCart = (_cart) => {
    if (!_cart || _cart?.lineItems?.length === 0) {
      return true;
    }
    return false;
  };

  const populateCanSendDraft = (_cart) => {
    const canSend = checkCart(_cart);
    setCanSendDraft(canSend);
  };

  useEffect(() => {
    populateCanSendDraft(cart);
  }, [cart]);

  return (
    <MenuItem
      disabled={canSendDraft}
      onClick={() => handleDialogSetOpen(true)}
      data-testid="sendDraft-dialog-button"
    >
      <Icon sx={{ mr: 1 }}>
        <DescriptionIcon />
      </Icon>
      <Typography>{copyText.Cart.CartTools.sendDraft}</Typography>
    </MenuItem>
  );
};

SendDraft.propTypes = {
  handleDialogSetOpen: func.isRequired,
};

export default SendDraft;
