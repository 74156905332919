import { React, useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CategoryCard from 'components/CategoryCard/CategoryCard';
import { useShopCategories, useShopProducts } from 'contexts';

import { lang } from 'language';
import placeholderImg from 'media/images/category_placeholder.png';
import SkeletonGrid from '../../components/SkeletonGrid/SkeletonGrid';
import ShopHeader from '../../components/ShopHeader/ShopHeader';

const CardsList = () => {
  const [currentCategories, setCurrentCategories] = useState();
  const categories = useShopCategories();
  const shopProducts = useShopProducts();

  const getCategoryImage = (categoryId) => {
    if (shopProducts) {
      const placeholderObj = { url: placeholderImg };
      const category = shopProducts[categoryId];
      const images = category[0]?.masterVariant.images;
      const imageObj = images ? images[0] : placeholderObj;

      const resp = imageObj ? imageObj.url : placeholderObj.url;
      return resp;
    }
    return null;
  };

  useEffect(() => {
    if (categories) {
      setCurrentCategories(categories);
    }
  }, [categories]);

  if (!currentCategories) {
    return <SkeletonGrid numItems={6} />;
  }

  return currentCategories.map((category) => {
    return (
      <Grid key={category.id} item>
        <Link component={RouterLink} to={`/category/${category.id}`} underline="none">
          <CategoryCard
            title={category.name[lang]}
            imgUrl={getCategoryImage(category.id)}
            imgAlt={`${category.name[lang]} category image`}
          />
        </Link>
      </Grid>
    );
  });
};

const Categories = () => {
  return (
    <>
      <ShopHeader />
      <Grid
        container
        spacing={4}
        data-testid="category-grid"
        sx={{ justifyContent: 'space-evenly', mt: 2 }}
      >
        <CardsList />
      </Grid>
    </>
  );
};
export default Categories;
