import getExchangeTotal from 'utils/CartFinancials/getExchangeTotal';

const getCartDiscounts = (cart) => {
  let totalPromoCodeDiscounts = 0;

  if (cart && cart.lineItems && cart.lineItems.length > 0) {
    cart.lineItems.forEach((lineItem) => {
      const discountsJson =
        lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json;
      const parsedPromoCodeDiscounts = discountsJson ? JSON.parse(discountsJson)[0] : null;
      totalPromoCodeDiscounts +=
        ((parsedPromoCodeDiscounts && parsedPromoCodeDiscounts.cent_amount) || 0) *
          lineItem.quantity || 0;
    });
  }
  return totalPromoCodeDiscounts;
};

const getRecyclingFee = (cart) => {
  let recyclingFee = 0;
  cart?.customLineItems?.forEach((lineItem) => {
    if (lineItem.slug === 'custom_recyclingFee') {
      recyclingFee += lineItem.taxedPrice?.totalGross?.centAmount || 0;
    }
  });
  return recyclingFee;
};

const getGiftCardTotals = (cart) => {
  let giftCards = 0;
  if (cart?.giftCards) {
    giftCards = cart.giftCards.reduce((acc, next) => {
      return acc + (next.amount?.centAmount || 0);
    }, 0);
  }
  const cartTotal = cart?.taxedPrice?.totalGross?.centAmount;

  if (giftCards > cartTotal) {
    return cartTotal;
  }
  return giftCards;
};

const getShippingTotals = (cart) => {
  let total = 0;
  cart?.customLineItems?.forEach((lineItem) => {
    if (lineItem.slug === 'custom_recyclingFee') {
      total += lineItem.totalPrice?.centAmount || 0;
    }
  });
  return total;
};

const getTax = (cart) => {
  if (cart?.taxedPrice) {
    const totalGross = cart?.taxedPrice?.totalGross?.centAmount;
    const totalNet = cart?.taxedPrice?.totalNet?.centAmount;
    return totalGross - totalNet;
  }
  return 0;
};

const getSubtotal = (cart) => {
  const exchanges = getExchangeTotal(cart);
  const giftCards = getGiftCardTotals(cart);
  const promos = getCartDiscounts(cart);
  const recyclingFee = getRecyclingFee(cart);
  const price =
    ((cart && cart.totalPrice && cart.totalPrice.centAmount) || 0) + promos - recyclingFee;
  if (price - promos - giftCards - exchanges < 0) {
    return 0;
  }
  const total = price - promos - giftCards - exchanges;
  return total;
};

const getTotalPrice = (cart) => {
  const giftCards = getGiftCardTotals(cart);
  const exchanges = getExchangeTotal(cart);
  let total = (cart?.taxedPrice?.totalGross?.centAmount || 0) - giftCards - exchanges;
  if (total < 0) {
    total = 0;
  }
  return total;
};

const getExchangeRefundTotal = (cart) => {
  if (cart?.is_exchange_order) {
    const giftCards = getGiftCardTotals(cart);
    const exchanges = getExchangeTotal(cart);
    const total = (cart?.taxedPrice?.totalGross?.centAmount || 0) - giftCards - exchanges;
    if (total > 0) {
      return 0;
    }
    return total;
  }
  return 0;
};
export default {
  getTotalPrice,
  getSubtotal,
  getShippingTotals,
  getCartDiscounts,
  getGiftCardTotals,
  getRecyclingFee,
  getTax,
  getExchangeRefundTotal,
};
